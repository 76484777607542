import {BrowserModule} from '@angular/platform-browser';
import {NgModule, APP_INITIALIZER} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';


import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {MenuItems} from './shared/menu-items/menu-items';
import {BreadcrumbsComponent} from './layout/admin/breadcrumbs/breadcrumbs.component';
import {AmplifyAngularModule, AmplifyService} from 'aws-amplify-angular';
import {DynamoDBService} from './service/ddb.service';
import {DynamoDBAdminService} from './service/ddb-admin.service';
import {DynamoDBHoofdService} from './service/ddb-hoofd.service';
 
import {AuthGuard} from './service/auth-guard.service';
import {RoleGuard} from './service/role-guard.service';
import {HelperService} from './service/helper.service';
import {CognitoService} from './service/cognito.service';
import {MailService} from './service/mail.service';
import {ToetsService} from './service/toets.service';
import {ExcelService} from './service/excel.service';
import {SchoolNaamService} from './service/schoolnaam.service';
import {SchoolDropdownService} from './service/schooldropdown.service';
import { ConfigService } from './service/config.service';
import {DynamoDBFilterService} from  './service/dynamodbfilter.service';
import { RapportService } from './service/rapport.service';




import {IntercomModule} from 'ng-intercom';
import {environment} from '../environments/environment';

import { HttpModule } from '@angular/http';

export function ConfigLoader(configService: ConfigService) {  
  return () => configService.load(environment.configFile); 
}

console.log(environment.intercomAppId)
@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpModule,
    HttpClientModule,
    AmplifyAngularModule,
    IntercomModule.forRoot({
      appId: environment.intercomAppId,
      updateOnRouterChange: true
    })
  ],
  schemas: [],
  providers: [MenuItems, AmplifyService, DynamoDBService , DynamoDBAdminService , DynamoDBHoofdService ,
    AuthGuard, RoleGuard, HelperService, CognitoService, 
    MailService, ToetsService, ExcelService, RapportService,
     SchoolNaamService, SchoolDropdownService, ConfigService, DynamoDBFilterService,
     {
       provide   : APP_INITIALIZER,
       useFactory: ConfigLoader,
       deps      : [ConfigService],
       multi     : true
     }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
