import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  user?: string;
  main: MainMenuItems[];
}



const MENUITEMS = [

  
  {
    label: 'Leerlingen',
    user: 'docent',
    main: [
      {
        state: 'klas-overzicht',
        short_label: 'KO',
        name: 'Groepsoverzicht',
        type: 'link',
        icon: 'feather icon-upload-cloud'
      },
      {
        state: 'klas-inschrijven',
        short_label: 'KI',
        name: 'Inschrijven',
        type: 'sub',
        icon: 'feather icon-calendar',
        children: [
          {
            state: 'mijnklas-inschrijven',
            name: 'Mijn groep'
          },
          {
            state: 'mijnschool-inschrijven',
            name: 'Mijn school'
          },
          {
            state: 'allescholen-inschrijven',
            name: 'Alle scholen'
          },
          {
            state: 'mijnschoolnj-inschrijven',
            name: 'Mijn school',
            badge: [
              {
                type: 'info',
                value: 'schooljaar'
              }
            ]
          },
          {
            state: 'eenschoolnj-inschrijven',
            name: 'Een school',
            badge: [
              {
                type: 'info',
                value: 'schooljaar'
              }
            ]
          }

        ]
      },
      {
        state: 'klas-uitschrijven',
        short_label: 'KI',
        name: 'Uitschrijven',
        type: 'link',
        icon: 'feather icon-calendar'
      }
    ]
  },
  {
    label: 'Resultaten',
    user: 'docent',
    main: [
      {
        state: 'klas-resultaat-RW',
        short_label: 'KORW',
        name: 'VT R&W',
        type: 'sub',
        icon: 'feather icon-upload-cloud',
        children: [
        {
          state: 'klas-resultaat',
          name: 'Groepsoverzicht',
        },
        {
          state: 'klasr-invoeren',
          name: 'Invoeren',
        },
        {
          state: 'klasr-verwijderen',
          name: 'Verwijderen',
        }]
      },
      {
        state: 'klas-resultaat-NP',
        short_label: 'KONP',
        name: 'VT NLD & PAP',
        type: 'sub',
        icon: 'feather icon-upload-cloud',
        children: [  
       {
          state: 'klas-resultaat-np',
          name: 'Groepsoverzicht'
        }, {
          state: 'klasr-invoeren-np',
          name: 'Invoeren'
        }, {
          state: 'klasr-verwijderen-np',
          name: 'Verwijderen'
        }]
      }
    ]
  },
  {
    label: 'Rapport',
    user: 'docent',
    main: [
      {
        state: 'vt-rw',
        short_label: 'RLLR',
        name: 'VT R&W',
        type: 'sub',
        icon: 'feather icon-file-text',
        children: [  {
          state: 'leerling-profiel',
          name: 'Leerlingprofiel'
        }, {
          state: 'groeps-profiel',
          name: 'Groepsprofiel'
        }, {
          state: 'school-profiel',
          name: 'Schoolprofiel'
        }]
      }
    ]
  },
  {
    label: 'Support',
    user: 'docent',
    main: [
      {
        state: 'documentation',
        short_label: 'D',
        name: 'Help & Advies',
        external: 'http://help.vt.ete.cw',
        type: 'external',
        icon: 'feather icon-monitor',
        target: true
      }
    ]
  },
  {
    label: 'Leerlingen',
    user: 'admin',
    main: [
      {
        state: 'leerling-toevoegen',
        short_label: 'ALT',
        name: 'Toevoegen',
        type: 'link',
        icon: 'feather icon-user-plus',
        badge: [
          {
            type: 'info',
            value: 'nieuwe'
          }
        ]

      },
      {
        state: 'leerling-wijzigen',
        short_label: 'ALW',
        name: 'Wijzigen',
        type: 'link',
        icon: 'feather icon-user-x'
      },
      {
        state: 'leerling-verwijderen',
        short_label: 'ALV',
        name: 'Verwijderen',
        type: 'link',
        icon: 'feather icon-user-minus'
      },
      {
        state: 'leerling-zoeken',
        short_label: 'ALZ',
        name: 'Zoeken',
        type: 'link',
        icon: 'feather icon-search'
      },
      {
        state: 'leerling-overstappen',
        short_label: 'LOS',
        name: 'Overstappen',
        type: 'link',
        icon: 'feather icon-move'
      },
    ]
  },
  {
    label: 'Docent',
    user: 'admin',
    main: [
      {
        state: 'bekijken-als',
        short_label: 'BKA',
        name: 'Bekijken als',
        type: 'link',
        icon: 'feather icon-user-check'
      },
      {
        state: 'admin-docent-leerlingen',
        short_label: 'ADL',
        name: 'Leerlingen',
        type: 'sub',
        icon: 'feather icon-users',
        children: [
          {
            state: 'klasoverzicht',
            name: 'Groepsoverzicht'
          },{
            state: 'klas-inschrijven',
            name: 'Inschrijven',
            type: 'sub',
            children: [
              {
                state: 'mijnklas-inschrijven',
                name: 'Mijn groep'
              },
              {
                state: 'mijnschool-inschrijven',
                name: 'Mijn school'
              },
              {
                state: 'allescholen-inschrijven',
                name: 'Alle scholen'
              },
              {
                state: 'mijnschoolnj-inschrijven',
                name: 'Mijn school',
                badge: [
                  {
                    type: 'info',
                    value: 'schooljaar'
                  }
                ]
              },
              {
                state: 'eenschoolnj-inschrijven',
                name: 'Een school',
                badge: [
                  {
                    type: 'info',
                    value: 'schooljaar'
                  }
                ]
              }
    
            ]
          }, {
            state: 'klas-uitschrijven',
            name: 'Uitschrijven'
          }
        ]
      },
      {
        state: 'admin-docent-resultaten',
        short_label: 'ADR',
        name: 'Resultaten',
        type: 'sub',
        icon: 'feather icon-clipboard',
        children: [
          {
            state: 'klas-resultaat-RW',
            short_label: 'KORW',
            name: 'VT R&W',
            type: 'sub',
            icon: 'feather icon-clipboard',
            children: [  {
              state: 'groepsoverzicht',
              name: 'Groepsoverzicht'
            }, {
              state: 'resultaten-invoeren',
              name: 'Invoeren'
            }, {
              state: 'resultaten-verwijderen',
              name: 'Verwijderen'
            }]
          },
          {
            state: 'klas-resultaat-NP',
            short_label: 'KONP',
            name: 'VT NLD & PAP',
            type: 'sub',
            icon: 'feather icon-clipboard',
            children: [  {
              state: 'klas-resultaat-np',
              name: 'Groepsoverzicht'
            }, {
              state: 'klasr-invoeren-np',
              name: 'Invoeren'
            }, {
              state: 'klasr-verwijderen-np',
              name: 'Verwijderen'
            }]
          }
        ]
      },
      {
        state: 'admin-docent-resultaten-rapport',
        short_label: 'ADA',
        name: 'Rapport',
        type: 'sub',
        icon: 'feather icon-file-text',
        children: [
          {
            state: 'vt-rw',
            short_label: 'ADARW',
            name: 'VT R&W',
            type: 'sub',
            icon: 'feather icon-file-text',
            children: [  {
              state: 'leerling-profiel',
              name: 'Leerlingprofiel'
            }, {
              state: 'groeps-profiel',
              name: 'Groepsprofiel'
            }, {
              state: 'school-profiel',
              name: 'Schoolprofiel'
            }]
          }
        ]
      }
    ]
  },
  {
    label: 'Rapportages',
    user: 'admin',
    main: [
      {
        state: 'admin-rapport',
        short_label: 'ARE',
        name: 'Genereren',
        type: 'link',
        icon: 'feather icon-file-text'
      }
    ]
  }
 ,{
  label: 'Hoofd',
  user: 'hoofd',
  main: [
    {
      state: 'bekijken-als-hoofd',
      short_label: 'BKH',
      name: 'Bekijken als',
      type: 'link',
      icon: 'feather icon-user-check',
    },

  ]
}
,{
  label: 'Leerlingen',
  user: 'hoofd',
  main: [
    {
      state: 'klas-overzicht',
      short_label: 'KOH',
      name: 'Groepsoverzicht',
      type: 'link',
      icon: 'feather icon-upload-cloud'
    },
    {
      state: 'klas-inschrijven',
      short_label: 'KI',
      name: 'Inschrijven',
      type: 'sub',
      icon: 'feather icon-calendar',
      children: [
        {
          state: 'mijnklas-inschrijven',
          name: 'Mijn groep'
        },
        {
          state: 'mijnschool-inschrijven',
          name: 'Mijn school'
        },
        {
          state: 'allescholen-inschrijven',
          name: 'Alle scholen'
        },
        {
          state: 'mijnschoolnj-inschrijven',
          name: 'Mijn school',
          badge: [
            {
              type: 'info',
              value: 'schooljaar'
            }
          ]
        },
        {
          state: 'eenschoolnj-inschrijven',
          name: 'Een school',
          badge: [
            {
              type: 'info',
              value: 'schooljaar'
            }
          ]
        }

      ]
    },
    {
      state: 'klas-uitschrijven',
      short_label: 'KI',
      name: 'Uitschrijven',
      type: 'link',
      icon: 'feather icon-calendar'
    }
  ]
},
{
  label: 'Resultaten',
  user: 'hoofd',
  main: [
    {
      state: 'klas-resultaat-RW',
      short_label: 'KORW',
      name: 'VT R&W',
      type: 'sub',
      icon: 'feather icon-upload-cloud',
      children: [
      {
        state: 'klas-resultaat',
        name: 'Groepsoverzicht',
      },
      {
        state: 'klasr-invoeren',
        name: 'Invoeren',
      },
      {
        state: 'klasr-verwijderen',
        name: 'Verwijderen',
      }]
    },
    {
      state: 'klas-resultaat-NP',
      short_label: 'KONP',
      name: 'VT NLD & PAP',
      type: 'sub',
      icon: 'feather icon-upload-cloud',
      children: [  
     {
        state: 'klas-resultaat-np',
        name: 'Groepsoverzicht'
      }, {
        state: 'klasr-invoeren-np',
        name: 'Invoeren'
      }, {
        state: 'klasr-verwijderen-np',
        name: 'Verwijderen'
      }]
    }
  ]
},
{
  label: 'Rapport',
  user: 'hoofd',
  main: [
    {
      state: 'vt-rw',
      short_label: 'RLLR',
      name: 'VT R&W',
      type: 'sub',
      icon: 'feather icon-file-text',
      children: [  {
        state: 'leerling-profiel',
        name: 'Leerlingprofiel'
      }, {
        state: 'groeps-profiel',
        name: 'Groepsprofiel'
      }, {
        state: 'school-profiel',
        name: 'Schoolprofiel'
      }]
    }
  ]
},
{
  label: 'Support',
  user: 'hoofd',
  main: [
    {
      state: 'documentation',
      short_label: 'D',
      name: 'Help & Advies',
      external: 'http://help.vt.ete.cw',
      type: 'external',
      icon: 'feather icon-monitor',
      target: true
    }
  ]
}
];


@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  };


}


