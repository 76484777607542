import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import { _ } from 'underscore';
import * as moment from 'moment-timezone';
import { ulid } from 'ulid';
const uuidv4 = require('uuid/v4');




@Injectable()
export class HelperService {


    constructor() { }

    

    async getExpDate(){
      const curadate = await moment().tz('America/Curacao').add(environment.expValue, "M").format('X');
      const curaepoch =await  +curadate;       // makes String a number
      return curaepoch;
    }
    
    
    async getCurrentTimeStamp(){
        return  moment().tz('America/Curacao').format();
    }

    async createUUID(){
        return await uuidv4();
    }

    async createULID(){
         return await ulid();
    }




}
