import { Injectable } from '@angular/core';
import { Toets } from './toets';
import { Taak } from './taak';
import { Vraag } from './vraag';


@Injectable()
export class ToetsService {

  getToetsen() {
    return [
     new Toets( 'E3','R&W E3', '03' ),
     new Toets( 'E4','R&W E4', '04' ),
     new Toets( 'E5','R&W E5', '05' ),
     new Toets( 'E6','R&W E6', '06' ),
     new Toets( 'E7','R&W E7', '07' ),
     new Toets( 'M6','R&W M6', '06' ),
     new Toets( 'M7','R&W M7', '07' )
    ];
  }
  
  getTaken() {
   return [
     new Taak('E3T1', 'Taak 1', 'E3' ),
     new Taak('E3T2', 'Taak 2', 'E3' ),
     new Taak('E3T3', 'Taak 3', 'E3' ),
     new Taak('E3T4', 'Taak 4', 'E3' ),
     new Taak('E3T5', 'Taak 5', 'E3' ),
     new Taak('E4T1', 'Taak 1', 'E4' ),
     new Taak('E4T2', 'Taak 2', 'E4' ),
     new Taak('E4T3', 'Taak 3', 'E4' ),
     new Taak('E4T4', 'Taak 4', 'E4' ),
     new Taak('E4T5', 'Taak 5', 'E4' ),
     new Taak('E4T6', 'Taak 6', 'E4' ),
     new Taak('E5T1', 'Taak 1', 'E5' ),
     new Taak('E5T2', 'Taak 2', 'E5' ),
     new Taak('E5T3', 'Taak 3', 'E5' ),
     new Taak('E5T4', 'Taak 4', 'E5' ),
     new Taak('E5T5', 'Taak 5', 'E5' ),
     new Taak('E5T6', 'Taak 6', 'E5' ),
     new Taak('E5T7', 'Taak 7', 'E5' ),
     new Taak('E5T8', 'Taak 8', 'E5' ),
     new Taak('E6T1', 'Taak 1', 'E6' ),
     new Taak('E6T2', 'Taak 2', 'E6' ),
     new Taak('E6T3', 'Taak 3', 'E6' ),
     new Taak('E6T4', 'Taak 4', 'E6' ),
     new Taak('E6T5', 'Taak 5', 'E6' ),
     new Taak('E6T6', 'Taak 6', 'E6' ),
     new Taak('E6T7', 'Taak 7', 'E6' ),
     new Taak('E6T8', 'Taak 8', 'E6' ),
     new Taak('E6T9', 'Taak 9', 'E6' ),
     new Taak('E7T1', 'Taak 1', 'E7' ),
     new Taak('E7T2', 'Taak 2', 'E7' ),
     new Taak('E7T3', 'Taak 3', 'E7' ),
     new Taak('E7T4', 'Taak 4', 'E7' ),
     new Taak('E7T5', 'Taak 5', 'E7' ),
     new Taak('E7T6', 'Taak 6', 'E7' ),
     new Taak('E7T7', 'Taak 7', 'E7' ),
     new Taak('E7T8', 'Taak 8', 'E7' ),
     new Taak('E7T9', 'Taak 9', 'E7' ),

     
     new Taak('M6T1', 'Taak 1', 'M6' ),
     new Taak('M6T2', 'Taak 2', 'M6' ),
     new Taak('M6T3', 'Taak 3', 'M6' ),
     new Taak('M6T4', 'Taak 4', 'M6' ),
     new Taak('M6T5', 'Taak 5', 'M6' ),
     new Taak('M6T6', 'Taak 6', 'M6' ),
     new Taak('M6T7', 'Taak 7', 'M6' ),
     new Taak('M6T8', 'Taak 8', 'M6' ),
     new Taak('M6T9', 'Taak 9', 'M6' ),
     new Taak('M7T1', 'Taak 1', 'M7' ),
     new Taak('M7T2', 'Taak 2', 'M7' ),
     new Taak('M7T3', 'Taak 3', 'M7' ),
     new Taak('M7T4', 'Taak 4', 'M7' ),
     new Taak('M7T5', 'Taak 5', 'M7' ),
     new Taak('M7T6', 'Taak 6', 'M7' ),
     new Taak('M7T7', 'Taak 7', 'M7' ),
     new Taak('M7T8', 'Taak 8', 'M7' ),
     new Taak('M7T9', 'Taak 9', 'M7' ),
     
    ];
  }

  getVragen(){
   return[
     new Vraag(10 ,'E3T1'),
     new Vraag(10 ,'E3T2'),
     new Vraag(9 , 'E3T3'),
     new Vraag(11 ,'E3T4'),
     new Vraag(10 ,'E3T5'),
     new Vraag(12 ,'E4T1'),
     new Vraag(8  ,'E4T2'),
     new Vraag(10 ,'E4T3'),
     new Vraag(9 , 'E4T4'),
     new Vraag(9 , 'E4T5'),
     new Vraag(12 ,'E4T6'),
     new Vraag(10 ,'E5T1'),
     new Vraag(7  ,'E5T2'),
     new Vraag(9  ,'E5T3'),
     new Vraag(13 ,'E5T4'),
     new Vraag(11 ,'E5T5'),
     new Vraag(9 , 'E5T6'),
     new Vraag(10 ,'E5T7'),
     new Vraag(11 ,'E5T8'),
     new Vraag(10 ,'E6T1'),
     new Vraag(10 ,'E6T2'),
     new Vraag(10 ,'E6T3'),
     new Vraag(10 ,'E6T4'),
     new Vraag(10 ,'E6T5'),
     new Vraag(10 ,'E6T6'),
     new Vraag(10 ,'E6T7'),
     new Vraag(10 ,'E6T8'),
     new Vraag(10 ,'E6T9'),
     new Vraag(10 ,'E7T1'),
     new Vraag(10 ,'E7T2'),
     new Vraag(10 ,'E7T3'),
     new Vraag(10 ,'E7T4'),
     new Vraag(10 ,'E7T5'),
     new Vraag(10 ,'E7T6'),
     new Vraag(10 ,'E7T7'),
     new Vraag(10 ,'E7T8'),
     new Vraag(10 ,'E7T9'),

     new Vraag(10 ,'M6T1'),
     new Vraag(10 ,'M6T2'),
     new Vraag(10 ,'M6T3'),
     new Vraag(10 ,'M6T4'),
     new Vraag(10 ,'M6T5'),
     new Vraag(11 ,'M6T6'),
     new Vraag(11 ,'M6T7'),
     new Vraag(12 ,'M6T8'),
     new Vraag(6 ,'M6T9'),
     new Vraag(10 ,'M7T1'),
     new Vraag(10 ,'M7T2'),
     new Vraag(10 ,'M7T3'),
     new Vraag(10 ,'M7T4'),
     new Vraag(10 ,'M7T5'),
     new Vraag(10 ,'M7T6'),
     new Vraag(10 ,'M7T7'),
     new Vraag(10 ,'M7T8'),
     new Vraag(10 ,'M7T9'),
    ];
  }


  getNPToetsen() {
    return [
     new Toets( 'PAPE4WS','Papiamentu WS E4', '04' ),
     new Toets( 'PAPE4BL','Papiamentu BL E4', '04' ),
     new Toets( 'PAPE4SP','Papiamentu SP E4', '04' ),
     new Toets( 'PAPE6WS','Papiamentu WS E6', '06' ),
     new Toets( 'PAPE6BL','Papiamentu BL E6', '06' ),
     new Toets( 'PAPE6SP','Papiamentu SP E6', '06' ),
     new Toets( 'PAPE6GR','Papiamentu GR E6', '06' ),
     new Toets( 'NLDE4WS','Nederlands WS E4', '04' ),
     new Toets( 'NLDE4BLPS','Nederlands BL E4 PAP', '04' ),
     new Toets( 'NLDE4BLNS','Nederlands BL E4 NL', '04' ),
     new Toets( 'NLDE4SP','Nederlands SP E4', '04' ),
     new Toets( 'NLDE5SP','Nederlands SP E5', '05' ),
     new Toets( 'NLDE6WS','Nederlands WS E6', '06' ),
     new Toets( 'NLDE6BLPS','Nederlands BL E6 PAP', '06' ),
     new Toets( 'NLDE6BLNS','Nederlands BL E6 NL', '06' ),
     new Toets( 'NLDE6SP','Nederlands SP E6', '06' ),
     new Toets( 'NLDE6GR','Nederlands GR E6', '06' ),
     new Toets( 'NLDE7SP','Nederlands SP E7', '07' ),
     new Toets( 'NLDE7GR','Nederlands GR E7', '07' ),
    ];
  }

  getNPTaken() {
    return [
      new Taak('PAPE4WST1', 'Taak 1', 'PAPE4WS' ),
      new Taak('PAPE4WST2', 'Taak 2', 'PAPE4WS' ),
      new Taak('PAPE6WST1', 'Taak 1', 'PAPE6WS' ),
      new Taak('PAPE6WST2', 'Taak 2', 'PAPE6WS' ),

      new Taak('PAPE4BLT1', 'Taak 1', 'PAPE4BL' ),
      new Taak('PAPE4BLT2', 'Taak 2', 'PAPE4BL' ),
      new Taak('PAPE6BLT1', 'Taak 1', 'PAPE6BL' ),
      new Taak('PAPE6BLT2', 'Taak 2', 'PAPE6BL' ),

      new Taak('PAPE4SPT1', 'Taak 1', 'PAPE4SP' ),
      new Taak('PAPE4SPT2', 'Taak 2', 'PAPE4SP' ),
      new Taak('PAPE6SPT1', 'Taak 1', 'PAPE6SP' ),
      new Taak('PAPE6SPT2', 'Taak 2', 'PAPE6SP' ),

      new Taak('PAPE6GRT1', 'Taak 1', 'PAPE6GR' ),
      new Taak('PAPE6GRT2', 'Taak 2', 'PAPE6GR' ),


      new Taak('NLDE4WST1', 'Taak 1', 'NLDE4WS' ),
      new Taak('NLDE4WST2', 'Taak 2', 'NLDE4WS' ),
      new Taak('NLDE6WST1', 'Taak 1', 'NLDE6WS' ),
      new Taak('NLDE6WST2', 'Taak 2', 'NLDE6WS' ),

      new Taak('NLDE4BLPST1', 'Taak 1', 'NLDE4BLPS' ),
      new Taak('NLDE4BLPST2', 'Taak 2', 'NLDE4BLPS' ),
      new Taak('NLDE4BLNST1', 'Taak 1', 'NLDE4BLNS' ),
      new Taak('NLDE4BLNST2', 'Taak 2', 'NLDE4BLNS' ),
      new Taak('NLDE6BLPST1', 'Taak 1', 'NLDE6BLPS' ),
      new Taak('NLDE6BLPST2', 'Taak 2', 'NLDE6BLPS' ),
      new Taak('NLDE6BLNST1', 'Taak 1', 'NLDE6BLNS' ),
      new Taak('NLDE6BLNST2', 'Taak 2', 'NLDE6BLNS' ),

      new Taak('NLDE4SPT1', 'Taak 1', 'NLDE4SP' ),
      new Taak('NLDE4SPT2', 'Taak 2', 'NLDE4SP' ),
      new Taak('NLDE5SPT1', 'Taak 1', 'NLDE5SP' ),
      new Taak('NLDE5SPT2', 'Taak 2', 'NLDE5SP' ),
      new Taak('NLDE6SPT1', 'Taak 1', 'NLDE6SP' ),
      new Taak('NLDE6SPT2', 'Taak 2', 'NLDE6SP' ),
      new Taak('NLDE7SPT1', 'Taak 1', 'NLDE7SP' ),
      new Taak('NLDE7SPT2', 'Taak 2', 'NLDE7SP' ),

      new Taak('NLDE6GRT1', 'Taak 1', 'NLDE6GR' ),
      new Taak('NLDE6GRT2', 'Taak 2', 'NLDE6GR' ),
      new Taak('NLDE7GRT1', 'Taak 1', 'NLDE7GR' ),
      new Taak('NLDE7GRT2', 'Taak 2', 'NLDE7GR' ),
     ];
   }

   getNPVragen(){
    return[
      new Vraag(15 ,'PAPE4WST1'),
      new Vraag(15 ,'PAPE4WST2'),
      new Vraag(15 ,'PAPE6WST1'),
      new Vraag(15 ,'PAPE6WST2'),

      new Vraag(15 ,'PAPE4BLT1'),
      new Vraag(15 ,'PAPE4BLT2'),
      new Vraag(15 ,'PAPE6BLT1'),
      new Vraag(15 ,'PAPE6BLT2'),

      new Vraag(10 ,'PAPE4SPT1'),
      new Vraag(10 ,'PAPE4SPT2'),
      new Vraag(7 ,'PAPE6SPT1'),
      new Vraag(7 ,'PAPE6SPT2'),

      new Vraag(8 ,'PAPE6GRT1'),
      new Vraag(8 ,'PAPE6GRT2'),

      new Vraag(15 ,'NLDE4WST1'),
      new Vraag(15 ,'NLDE4WST2'),
      new Vraag(15 ,'NLDE6WST1'),
      new Vraag(15 ,'NLDE6WST2'),

      new Vraag(15 ,'NLDE4BLPST1'),
      new Vraag(15 ,'NLDE4BLPST2'),
      new Vraag(15 ,'NLDE6BLPST1'),
      new Vraag(15 ,'NLDE6BLPST2'),

      new Vraag(16 ,'NLDE4BLNST1'),
      new Vraag(14 ,'NLDE4BLNST2'),
      new Vraag(15 ,'NLDE6BLNST1'),
      new Vraag(15 ,'NLDE6BLNST2'),

      new Vraag(15 ,'NLDE4SPT1'),
      new Vraag(15 ,'NLDE4SPT2'),
      new Vraag(15 ,'NLDE5SPT1'),
      new Vraag(15 ,'NLDE5SPT2'),

      new Vraag(11 ,'NLDE6SPT1'),
      new Vraag(11 ,'NLDE6SPT2'),
      new Vraag(11 ,'NLDE7SPT1'),
      new Vraag(11 ,'NLDE7SPT2'),

      new Vraag(4 ,'NLDE6GRT1'),
      new Vraag(4 ,'NLDE6GRT2'),
      new Vraag(4 ,'NLDE7GRT1'),
      new Vraag(4 ,'NLDE7GRT2'),




      

     ];
   }

}