import { Injectable } from '@angular/core';
import { SchoolNaam } from './schoolnaam';



@Injectable()
export class SchoolNaamService {

  getSchoolNamen() {
    return [
     new SchoolNaam( '01','Oranjeschool' ),
     new SchoolNaam( '02','Angela Jessurun Simon' ),
     new SchoolNaam( '03','Prins Bernhardschool' ),
     new SchoolNaam( '04','Skol Basiko Elis Juliana' ),
     new SchoolNaam( '05','Prinses Margrietschool' ),
     new SchoolNaam( '06','Johan van Walbeeckschool' ),
     new SchoolNaam( '07','C.J. Krijtschool' ),
     new SchoolNaam( '08','Joan Mauritsschool' ),
     new SchoolNaam( '09','A. E. Goiloschool' ),
     new SchoolNaam( '10','C.M.L. Maduroschool' ),
     new SchoolNaam( '11','P.L. Brionschool' ),
     new SchoolNaam( '12','J.W.Th. Schotborghschool' ),
     new SchoolNaam( '13','M.M. Römerschool' ),
     new SchoolNaam( '14','Skol Básiko Cola Debrot' ),
     new SchoolNaam( '15','Skol Myrna Dovale' ),
     new SchoolNaam( '16','Skol dr. Nelly Winkel' ),
     new SchoolNaam( '17','G.O.G.' ),
     new SchoolNaam( '18','Kolegio Annie Koenraad' ),
     new SchoolNaam( '19','Kolegio San Dominico' ),
     new SchoolNaam( '20','Kolegio Don Sarto' ),
     new SchoolNaam( '21','Mgr. Niewindt College' ),
     new SchoolNaam( '22','St. Albertus College' ),
     new SchoolNaam( '23','St. Franciscus College' ),
     new SchoolNaam( '24','St. Antonius College' ),
     new SchoolNaam( '25','Kolegio San Martinus de Porres' ),
     new SchoolNaam( '26','St. Paulus College' ),
     new SchoolNaam( '27','St. Joris College' ),
     new SchoolNaam( '28','Glorieux College' ),
     new SchoolNaam( '29','Kolegio Santa Clara' ),
     new SchoolNaam( '30','Kolegio San Hose' ),
     new SchoolNaam( '31','Kolegio Mgr. Willem Ellis' ),
     new SchoolNaam( '32','Kolegio Chaya Willems' ),
     new SchoolNaam( '33','Kolegio Bellefaas Martis' ),
     new SchoolNaam( '34','Kolegio Dodo Palm' ),
     new SchoolNaam( '35','St. Margaretha College' ),
     new SchoolNaam( '36','Sta. Rosa de Lima College' ),
     new SchoolNaam( '37','Kolegio Iris Bruyning' ),
     new SchoolNaam( '38','Kolegio Maria Auxiliadora' ),
     new SchoolNaam( '39','Kolegio Nechi Pieters' ),
     new SchoolNaam( '40','Berg Carmel College' ),
     new SchoolNaam( '41','Coromoto College' ),
     new SchoolNaam( '42','Fatima  College' ),
     new SchoolNaam( '43','Kolegio Vigdis Jonckheer-Mensing' ),
     new SchoolNaam( '44','Kolegio Santa Famia' ),
     new SchoolNaam( '45','Kennedy LOM school' ),
     new SchoolNaam( '46','Marnixschool' ),
     new SchoolNaam( '47','Dr. Albert Schweitzerschool' ),
     new SchoolNaam( '48','Dividivi LOM school' ),
     new SchoolNaam( '49','Adventschool Mahuma Zuid' ),
     new SchoolNaam( '50','Adventschool Koraal Specht' ),
     new SchoolNaam( '51','Adventschool Bonam' ),
     new SchoolNaam( '52','Emmy Berthold school' ),
     new SchoolNaam( '53','Skol Básiko Immanuel' ),
     new SchoolNaam( '54','Kolegio Erasmo' ),
     new SchoolNaam( '55','Pipita Basisschool' ),
     new SchoolNaam( '56','H.J.A. Schroederschool' ),
     new SchoolNaam( '57','Klein College' ),
     new SchoolNaam( '58','College Op Maat' ),
     new SchoolNaam( '59','De Meander' ),
     new SchoolNaam( '71','Muchanan Balente College' ),
     new SchoolNaam( '72','Privéschool de Rakkers' ),
     new SchoolNaam( '73','Skol M.C. Piar' ),
     new SchoolNaam( '74','Skol Marieta Alberto ZMLK/MLK' ),
     new SchoolNaam( '75','Prof. Dr. I.C. van Houteschool' ),
     new SchoolNaam( '76','Ala Blancaschool' ),
     new SchoolNaam( '77','Blenchischool' ),
     new SchoolNaam( '78','Br. Rigobertusschool' ),
     new SchoolNaam( '79','Fr. Evoniusschool' ),
     new SchoolNaam( '80','Kolegio Sur Herman Joseph' ),
     new SchoolNaam( '81','Soeur Hedwigschool' ),
     new SchoolNaam( '99','Kolegio Siña Duru' )
    
    ];
  }
  
  



}