import { Injectable } from '@angular/core';
import { Router, CanActivate , ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private amplifyService: AmplifyService, private router: Router) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('RoleGuard#canActivate called');

    const user =  await this.amplifyService.auth().currentAuthenticatedUser()
    console.log(user.signInUserSession.accessToken);
     
    console.log(user.signInUserSession.accessToken.payload['cognito:groups']+"");

    if (""+user.signInUserSession.accessToken.payload['cognito:groups'] === next.data.role ) {
      return true;
    } 
    // navigate to not found page
    this.router.navigate(['maintenance/error/404']);
    return false;

  
   }
}