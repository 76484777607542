import {Injectable} from "@angular/core";
import { Auth, Logger } from 'aws-amplify';
import {environment} from "../../environments/environment";
import { _ } from 'underscore';
import { getCurrentDebugContext } from "@angular/core/src/view/services";
import { letProto } from "rxjs-compat/operator/let";
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import * as SES from "aws-sdk/clients/ses";
import * as DynamoDB from "aws-sdk/clients/dynamodb";
import { AnalysisSchemeStatusList } from "aws-sdk/clients/cloudsearch";
import {HelperService} from "./helper.service";
import {CognitoService} from "./cognito.service";
import * as Lambda from "aws-sdk/clients/lambda";
import { HttpClient ,HttpHeaders } from '@angular/common/http';






const logger = new Logger('DynamoDBService');


@Injectable()
export class DynamoDBService {


    constructor(public hps: HelperService, public cgn: CognitoService ,private http: HttpClient ) { }

    
    signOut() {
      return Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));
  }


    
  

    getDC() {
      return Auth.currentCredentials()
        .then(credentials => new DocumentClient({ credentials: credentials , region: environment.region }))  //correctClockSkew: true,
        .catch(err => logger.debug('error getting document client', err));
    };

    getLC() {
      return Auth.currentCredentials()
        .then(credentials => new Lambda({ credentials: credentials , region: environment.region }))  //correctClockSkew: true,
        .catch(err => logger.debug('error getting lambda client', err));
    };



      getKlas(schoolbestuur , schoolnr , groepnr , klasnr){



          const params = {
            TableName: environment.ddbName,
            IndexName: environment.ddbNameIndex1 ,
            KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
            FilterExpression : 'SchoolNr = :snr and GroepNr = :gnr and KlasNr = :knr and Ingeschreven = :ing' ,
            ExpressionAttributeValues: {
              ':sbs': schoolbestuur ,
              ':scj': environment.schoolJaar ,
              ':snr': schoolnr ,
              ':gnr': groepnr ,
              ':knr' : klasnr ,
              ':ing' : true
            },
            ExpressionAttributeNames: {
              '#key' : 'SchoolBestuur',
              '#sortkey' : 'SchoolJaar'
            },
            Limit: 100,
            ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum'
          };
  
        
          return this.getDC()
          .then(client  =>  (client as DocumentClient).query(params).promise())
          .then(data => { 
            return data ;     
          })
          .catch(err => console.log(err));
  
        };
   

    
  
     



  
    
      
   

    getLeerlingInfo(){


        const params = {
          'TableName': environment.ddbName,
          'IndexName': environment.ddbNameIndex1 ,
          'Key': { "StudentId": '7ff726dd-c350-4334-a270-3466d00d2bf7' ,"SchoolJaar": environment.schoolJaar}
      
        };

      return  this.getDC()
    .then(client  => (client as DocumentClient).get(params).promise())
    .then(data => { return data.Item.SchoolJaar})
    .catch(err => console.log(err));
      }


      async getMijnKlas(){

        const docent = await this.cgn.getUserInfo() ;
        let startkey: any;

        let params = {
          TableName: environment.ddbName,
          IndexName: environment.ddbNameIndex1 ,
          KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
          FilterExpression : 'SchoolNr = :snr and GroepNr = :gnr and KlasNr = :knr and Ingeschreven = :ing' ,
          ExpressionAttributeValues: {
            ':sbs': docent['schoolbestuur'] ,
            ':scj': environment.schoolJaar,
            ':snr': docent['schoolnr'] ,
            ':gnr': docent['groepnr'] ,
            ':knr' : docent['klasnr'] ,
            ':ing' : true
          },
          ExpressionAttributeNames: {
            '#key' : 'SchoolBestuur',
            '#sortkey' : 'SchoolJaar'
          },
          ProjectionExpression: 'Achternaam ,Voornamen,StudentId,ID,Geboortedatum,SchoolJaar',
          ExclusiveStartKey :startkey
        }          
        let items = [];
       
          while (true) {
        const db = await this.getDC();
        const data = await (db as DocumentClient).query(params).promise();
        items = items.concat(data.Items);
   
       if (! data.LastEvaluatedKey) {
         break;
       }
       
       params.ExclusiveStartKey = data.LastEvaluatedKey;
     }
     return _.sortBy(items, 'Achternaam');
        
     };



     async getMijnKlasUit(){
       //Uitgschreven van mijn klas die niet ingeschreven zijn
      const docent = await this.cgn.getUserInfo() ;
      let startkey: any;

      let params = {
        TableName: environment.ddbName,
        IndexName: environment.ddbNameIndex1 ,
        KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
        FilterExpression : 'SchoolNr = :snr and GroepNr = :gnr and KlasNr = :knr and Ingeschreven = :ing' ,
        ExpressionAttributeValues: {
          ':sbs': docent['schoolbestuur'] ,
          ':scj': environment.schoolJaar,
          ':snr': docent['schoolnr'] ,
          ':gnr': docent['groepnr'] ,
          ':knr' : docent['klasnr'] ,
          ':ing' : false
        },
        ExpressionAttributeNames: {
          '#key' : 'SchoolBestuur',
          '#sortkey' : 'SchoolJaar'
        },
        ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar',
        ExclusiveStartKey :startkey
      }          
      let items = [];
     
        while (true) {
      const db = await this.getDC();
      const data = await (db as DocumentClient).query(params).promise();
      items = items.concat(data.Items);
 
     if (! data.LastEvaluatedKey) {
       break;
     }

     params.ExclusiveStartKey = data.LastEvaluatedKey;
   }
   return _.sortBy(items, 'Achternaam');
    
   };

   async getMijnSchoolUit(){
    //Uitgschreven van mijn school die niet ingeschreven zijn

   const docent = await this.cgn.getUserInfo() ;
   let startkey: any;

   let params = {
     TableName: environment.ddbName,
     IndexName: environment.ddbNameIndex1 ,
     KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
     FilterExpression : 'SchoolNr = :snr and GroepNr > :gnr and KlasNr > :knr and Ingeschreven = :ing' ,
     ExpressionAttributeValues: {
       ':sbs': docent['schoolbestuur'] ,
       ':scj': environment.schoolJaar,
       ':snr': docent['schoolnr'] ,
       ':gnr': '0' ,
       ':knr' : '0' ,
       ':ing' : false
     },
     ExpressionAttributeNames: {
       '#key' : 'SchoolBestuur',
       '#sortkey' : 'SchoolJaar'
     },
     ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,GroepNr,KlasNr,ID',
     ExclusiveStartKey :startkey
   }          
   let items = [];
  
     while (true) {
   const db = await this.getDC();
   const data = await (db as DocumentClient).query(params).promise();
   items = items.concat(data.Items);

  if (! data.LastEvaluatedKey) {
    break;
  }
  
  params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');
   
};


     async getIdsMijnKlas(){
       //returns an array of studentids from the teachers class
      const docent = await this.cgn.getUserInfo() ;
      let startkey: any;

      let params = {
        TableName: environment.ddbName,
        IndexName: environment.ddbNameIndex1 ,
        KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
        FilterExpression : 'SchoolNr = :snr and GroepNr = :gnr and KlasNr = :knr and Ingeschreven = :ing' ,
        ExpressionAttributeValues: {
          ':sbs': docent['schoolbestuur'] ,
          ':scj': environment.schoolJaar,
          ':snr': docent['schoolnr'] ,
          ':gnr': docent['groepnr'] ,
          ':knr' : docent['klasnr'] ,
          ':ing' : true
        },
        ExpressionAttributeNames: {
          '#key' : 'SchoolBestuur',
          '#sortkey' : 'SchoolJaar'
        },
        ProjectionExpression: 'StudentId,SchoolJaar',
        ExclusiveStartKey :startkey
      }          
      let items = [];
     
        while (true) {
      const db = await this.getDC();
      const data = await (db as DocumentClient).query(params).promise();
      items = items.concat(data.Items);
 
     if (! data.LastEvaluatedKey) {
       break;
     }
     
     params.ExclusiveStartKey = data.LastEvaluatedKey;
   }
   //return _.pluck(items, 'StudentId');
     return items; 
   };

   async getOnlyIdsMijnKlas(){
    //returns an array of studentids only  from the teachers class
   const docent = await this.cgn.getUserInfo() ;
   let startkey: any;

   let params = {
     TableName: environment.ddbName,
     IndexName: environment.ddbNameIndex1 ,
     KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
     FilterExpression : 'SchoolNr = :snr and GroepNr = :gnr and KlasNr = :knr and Ingeschreven = :ing' ,
     ExpressionAttributeValues: {
       ':sbs': docent['schoolbestuur'] ,
       ':scj': environment.schoolJaar,
       ':snr': docent['schoolnr'] ,
       ':gnr': docent['groepnr'] ,
       ':knr' : docent['klasnr'] ,
       ':ing' : true
     },
     ExpressionAttributeNames: {
       '#key' : 'SchoolBestuur',
       '#sortkey' : 'SchoolJaar'
     },
     ProjectionExpression: 'StudentId',
     ExclusiveStartKey :startkey
   }          
   let items = [];
  
     while (true) {
   const db = await this.getDC();
   const data = await (db as DocumentClient).query(params).promise();
   items = items.concat(data.Items);

  if (! data.LastEvaluatedKey) {
    break;
  }
  
  params.ExclusiveStartKey = data.LastEvaluatedKey;
}
let finalArray = await  items.map(function (obj) {
  return obj.StudentId;
});
  return finalArray; 
};

async getOnlyIdsMijnGroep(){
  //returns an array of studentids only  from the teachers group example 4A ,4B , 4C ,4D
 const docent = await this.cgn.getUserInfo() ;
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'SchoolNr = :snr and GroepNr = :gnr and Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': docent['schoolbestuur'] ,
     ':scj': environment.schoolJaar,
     ':snr': docent['schoolnr'] ,
     ':gnr': docent['groepnr'] ,
     ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'StudentId',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
let finalArray = await  items.map(function (obj) {
return obj.StudentId;
});
return finalArray; 
};

   async getIdsenNamenMijnKlas(){
    //returns an array of studentids and names from the teachers class for dropdownlist
   const docent = await this.cgn.getUserInfo() ;
   let startkey: any;

   let params = {
     TableName: environment.ddbName,
     IndexName: environment.ddbNameIndex1 ,
     KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
     FilterExpression : 'SchoolNr = :snr and GroepNr = :gnr and KlasNr = :knr and Ingeschreven = :ing' ,
     ExpressionAttributeValues: {
       ':sbs': docent['schoolbestuur'] ,
       ':scj': environment.schoolJaar,
       ':snr': docent['schoolnr'] ,
       ':gnr': docent['groepnr'] ,
       ':knr' : docent['klasnr'] ,
       ':ing' : true
     },
     ExpressionAttributeNames: {
       '#key' : 'SchoolBestuur',
       '#sortkey' : 'SchoolJaar'
     },
     ProjectionExpression: 'StudentId,Voornamen,Achternaam',
     ExclusiveStartKey :startkey
   }          
   let items = [];
  
     while (true) {
   const db = await this.getDC();
   const data = await (db as DocumentClient).query(params).promise();
   items = items.concat(data.Items);

  if (! data.LastEvaluatedKey) {
    break;
  }
  
  params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return  _.sortBy(items, "Achternaam");
};

      async aBaby()  {

       let startkey: any;
        
        let params = {
          'TableName': environment.ddbName,
          Limit: 2,
          ExclusiveStartKey :startkey

        };

        let items = [];
    while (true) {
      const dynamoDb = await  this.getDC();

      const data = await (dynamoDb as DocumentClient).scan(params).promise();
  
      items = items.concat(data.Items);
  
      if (! data.LastEvaluatedKey) {
        break;
      }
      console.log(data);
      params.ExclusiveStartKey = data.LastEvaluatedKey;
    }
    return _.sortBy(items, "Achternaam");
       
      
    
    };


    async batchitems()  {
     const mybatch = await this.getIdsMijnKlas();

      let params = {
        "RequestItems" : {
            [environment.ddbName] : {
                "Keys" : mybatch
            }
        }
    }

     const dynamoDb = await  this.getDC();

     const data = await (dynamoDb as DocumentClient).batchGet(params).promise();
   
   return data ;
      
   };

   async batchg()  {
     let next ;
    const MAX_BATCH_GET = 2;

    const mybatch = await this.getIdsMijnKlas();
    let maxKeys = mybatch.slice(0, MAX_BATCH_GET);
  let remainingKeys = mybatch.slice(MAX_BATCH_GET);

     let params = {
       "RequestItems" : {
           [environment.ddbName] : {
               "Keys" : maxKeys
           },
       },
   };

    const dynamoDb = await  this.getDC();

    let  response = await (dynamoDb as DocumentClient).batchGet(params).promise();

    let {
      Responses: { [environment.ddbName]: result },
      UnprocessedKeys: { [environment.ddbName]: unprocessedKeys },
      ...meta
    } = response;

    next = unprocessedKeys || [];
  next.push(...remainingKeys);

  
  return {
    result,
    meta: {
      ...meta,
      next,
    },
  };
     
  };

   async updateUitschrijfStudentMijnKlas(students){

        
    const dynamoDb = await  this.getDC();
    const docent =  await this.cgn.getUserInfo();
    const expdate = await this.hps.getExpDate();
    const timest = await this.hps.getCurrentTimeStamp();
    const mystudents = await _.map(students, function (student) {
      return _.omit(student, ['Achternaam', 'Geboortedatum','Voornamen']);
  }); // nos ta  kita e infonan paso nos no mester save nan den e logdatabase e hashkey i sortkey ta unique kaba

    let promises;
    
    promises = students.map(function (students) {

       
        const params = {
            TableName: environment.ddbName,
            IndexName: environment.ddbNameIndex1,
            Key: {
              'StudentId' : students.StudentId, 
              'SchoolJaar' : students.SchoolJaar
            },
            UpdateExpression: 'set Ingeschreven = :ing',
            ConditionExpression: 'attribute_exists(StudentId) AND attribute_exists(SchoolJaar) ',
            ExpressionAttributeValues: { 
              ':ing': false
            },
            ReturnValues:'UPDATED_NEW',
            
        };
        return (dynamoDb as DocumentClient).update(params).promise();
    });

     // Make sure to return the promise    
    return Promise.all(promises).then(function (responses: any) {


        console.log(responses);
        console.log(docent);
        const logparams = {
          TableName: environment.ddbLogs,
          Item: {
              'subId': docent['sub'],
              'timestamp': timest,
              'bewerking': 'Ingeschreven=false',
              'wie':  mystudents,
              'wat': 'Uitschrijven leerling uit klas',
              'expDate': expdate
          }
        };

        return  (dynamoDb as DocumentClient).put(logparams).promise();

    })
    .then(data => { 
      return data ;     
    })
    .catch(function(err) {
        console.log(err);
        this.sendMail(err);
    });


}

async updateInschrijvenStudentMijnKlas(students){

        
  const dynamoDb = await this.getDC();
  const docent =  await this.cgn.getUserInfo();
  const expdate = await this.hps.getExpDate();
  const timest = await this.hps.getCurrentTimeStamp();
  const mystudents = await _.map(students, function (student) {
    return _.omit(student, ['Achternaam', 'Geboortedatum','Voornamen']);
}); // nos ta  kita e infonan paso nos no mester save nan den e logdatabase e hashkey i sortkey ta unique kaba

  let promises;
  
  promises = students.map(function (students) {

     
      const params = {
          TableName: environment.ddbName,
          IndexName: environment.ddbNameIndex1,
          Key: {
            'StudentId' : students.StudentId, 
            'SchoolJaar' : students.SchoolJaar
          },
          UpdateExpression: 'set Ingeschreven = :ing , SchoolBestuur = :sbs , SchoolNr = :snr , GroepNr = :gnr , KlasNr = :knr ',
          ConditionExpression: 'attribute_exists(StudentId) AND attribute_exists(SchoolJaar) ',
          ExpressionAttributeValues: { 
            ':ing': true,
            ':sbs': docent['schoolbestuur'] ,
            ':snr': docent['schoolnr'] ,
            ':gnr': docent['groepnr'] ,
            ':knr' : docent['klasnr'] 
          },
          ReturnValues:'UPDATED_NEW',
          
      };
      return (dynamoDb as DocumentClient).update(params).promise();
  });

   // Make sure to return the promise    
  return Promise.all(promises).then(function (responses: any) {



      console.log(responses);
      const logparams = {
        TableName: environment.ddbLogs,
        Item: {
            'subId': docent['sub'],
            'timestamp': timest,
            'bewerking': 'Ingeschreven=true',
            'wie':  mystudents,
            'wat': `Inschrijven leerling in klas`,
            'expDate': expdate
        }
      };

      return  (dynamoDb as DocumentClient).put(logparams).promise();

  })
  .then(data => { 
    return data ;     
  })
  .catch(function(err) {
      console.log(err);
      this.sendMail(err);
  });


}








   async maybach(students)  {

    const mybatch = _.map(students, function (student) {
      return _.omit(student, ['Achternaam', 'Geboortedatum','Voornamen']);
  });

    console.log(mybatch);

     let params = {
       "RequestItems" : {
           [environment.ddbName] : {
               "Keys" : mybatch
           }
       }
   }

    const dynamoDb = await  this.getDC();

    const data = await (dynamoDb as DocumentClient).batchGet(params).promise();
  
  return data ;
     
  };

  
   async mijnSchoolDezeJaarNietIngeschreven()  {

    const docent = await this.cgn.getUserInfo() ;
    let startkey: any;

    let params = {
      TableName: environment.ddbName,
      IndexName: environment.ddbNameIndex1 ,
      KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
      FilterExpression : 'SchoolNr = :snr and Ingeschreven = :ing' ,
      ExpressionAttributeValues: {
        ':sbs': docent['schoolbestuur'] ,
        ':scj': environment.schoolJaar,
        ':snr': docent['schoolnr'] ,
        ':ing' : false
      },
      ExpressionAttributeNames: {
        '#key' : 'SchoolBestuur',
        '#sortkey' : 'SchoolJaar'
      },
      ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,ID',
      ExclusiveStartKey :startkey
    }          
    let items = [];
   
      while (true) {
    const db = await this.getDC();
    const data = await (db as DocumentClient).query(params).promise();
    items = items.concat(data.Items);

   if (! data.LastEvaluatedKey) {
     break;
   }
   
   params.ExclusiveStartKey = data.LastEvaluatedKey;
 }
 
 return _.sortBy(items, 'Achternaam');
  
    };

   
   

       async getMyBaby()  {

      
        
          const params = {
            'TableName': environment.ddbName,
            'IndexName': environment.ddbNameIndex1 ,
            'Key': { "StudentId": '7ff726dd-c350-4334-a270-3466d00d2bf7' ,"SchoolJaar": environment.schoolJaar}
        
          };
         
        try {
          const db = await  this.getDC();
          const data = await (db as DocumentClient).get(params).promise();

          console.log(data);
          
    
    
          return data;
        } catch (err) {
    
         
    
      
          return 'FAILED';
         
        }
      
      };

      async addResultaat(vragenParams, formvalue){
        let text;
        let noti;
        const dynamoDb = await this.getDC();
        const docent =   await this.cgn.getUserInfo();
        //const toetsid =  await this.hps.createUUID();
        //const stuid =    await this.hps.createULID();
        const timest =   await this.hps.getCurrentTimeStamp(); 
        let Item = vragenParams;
        Item.ToetsID = formvalue.studentIdInput  + formvalue.toetsNaamInput + formvalue.taakNaamInput;
        Item.StudentId = formvalue.studentIdInput;
        Item.ToetsNaam = formvalue.toetsNaamInput;
        Item.TaakNaam   = formvalue.taakNaamInput;
        Item.SubId = docent['sub'];
        Item.Timestamp = timest;
        Item.Geldig = true;
        Item.SchoolJaar = environment.schoolJaar;

         let cijferparams = {
              TableName: environment.ddbCijfer,
               Item,
               ConditionExpression: 'attribute_not_exists(ToetsID) AND attribute_not_exists(SchoolJaar) ',
                
            };
      
        try{
        const data = await (dynamoDb as DocumentClient).put(cijferparams).promise(); 
        noti ='success'
        text ='opslaan van resultaat is succesvol voltooid!'
        return {noti, text} ;
        }catch (error) {

          if (error.code === 'ConditionalCheckFailedException') {
             noti ='duplicaat'
            text ='leerling resultaat is al opgeslagen!'  
          return {noti, text} ;

          }
          else{

          console.log(error);
          noti ='fout'
          text ='opslaan van resultaat is fout gegaan!'  
          return {noti, text} ;
          }
        }
      }

      async addNewStudent(formvalue){
        let text;
        let noti;
        const dynamoDb = await this.getDC();
 
        let Item = formvalue;
         let params = {
              TableName: environment.ddbName,
              IndexName: environment.ddbNameIndex1,
               Item,
               ConditionExpression: 'attribute_not_exists(StudentId) AND attribute_not_exists(SchoolJaar) ',
                
            };
      
        try{
        const data = await (dynamoDb as DocumentClient).put(params).promise(); 
        noti ='success'
        text ='toevoegen van nieuwe leerling is succesvol'
        return {noti, text} ;
        }catch (error) {

          if (error.code === 'ConditionalCheckFailedException') {
             noti ='duplicaat'
            text ='leerling is niet toegevoegd want ID en schooljaar bestaat al'  
          return {noti, text} ;

          }
          else{

          console.log(error);
          noti ='fout'
          text ='toevoegen van leerling is fout gegaan !'  
          return {noti, text} ;
          }
        }
      }

      async getmijnKlasResultaten(VTSoort)  {
        // eerst krijgt lijst met ids van alle leerlingen van deze schooljaar in klas den batch allemaal voor cijfers deze schooljaar te vinden
        const students = await this.getIdsMijnKlas();
        const dynamoDb = await  this.getDC();
        let promises;

        promises = students.map(function (students) {

       
          const params = {
              TableName: environment.ddbCijfer,
              IndexName: environment.ddbCijferStudent,
              KeyConditionExpression : '#key = :stuid and #sortkey = :scj' ,
              ExpressionAttributeValues: {
                ':stuid': students.StudentId,
                ':scj': students.SchoolJaar,

              },
              ExpressionAttributeNames: {
                '#key' : 'StudentId',
                '#sortkey' : 'SchoolJaar'
              },
              
                           
          };

          return (dynamoDb as DocumentClient).query(params).promise();
      });
          // Make sure to return the promise    
      return Promise.all(promises).then(function (responses: any) {
      var figo = [];
      responses.forEach(function(element) {
 
        if (element.Items.length > 0){
           figo.push(element.Items);
        }
      });

      let preresult = _.sortBy( _.flatten(figo), 'TaakNaam');


      if (VTSoort === 'NLD&PAP') {

        return preresult.filter(item => ['PAPE4WS','PAPE4BL','PAPE4SP','PAPE6WS','PAPE6BL','PAPE6SP', 'PAPE6GR','NLDE4WS','NLDE4BLPS','NLDE4BLNS','NLDE4SP','NLDE5SP','NLDE6WS','NLDE6BLPS','NLDE6BLNS','NLDE6SP','NLDE6GR','NLDE7SP','NLDE7GR'].includes(item.ToetsNaam));


        //  block of code to be executed if condition1 is true
      } else if (VTSoort === 'R&W') {
       
        return preresult.filter(item => ['E3','E4','E5','E6','E7','M6', 'M7'].includes(item.ToetsNaam));

      } else {
        
        console.log('ERROR GEEN VT SOORT GEVONDEN het is geen VT R&W en ook geen VT NLD & PAP !!!')
        return preresult ;
      }
        


       
     })

   };

   async getmijnKlasResultatenmetNamenenIds(VTSoort){


    let a1 = await this.getmijnKlasResultaten(VTSoort);
    let a2 =  await this.getIdsenNamenMijnKlas();
    var output = [];
    let result = a1.slice(0);

for (var i = 0 ; i < result.length ; i++){
  for (var j = 0; j < a2.length ; j++){
    if (result[i].StudentId == a2[j].StudentId){
      result[i].Voornamen = a2[j].Voornamen;
      result[i].Achternaam = a2[j].Achternaam;
      result[i].Naam =  a2[j].Voornamen.toLowerCase() +' '+ a2[j].Achternaam.toLowerCase();

    }
  };  
};
console.log(result)
 
return result;

     }

      async mijnKlasResultaten()  {
      //tijdelijke oplosing  het gebruiken van subid is niet beter dan van studentids
        const docent = await this.cgn.getUserInfo() ;
        let startkey: any;
    
        let params = {
          TableName: environment.ddbCijfer,
          IndexName: environment.ddbCijferLeraar ,
          KeyConditionExpression : '#key = :sub and #sortkey = :scj' ,
          FilterExpression : 'Geldig = :gdg' ,
          ExpressionAttributeValues: {
            ':sub': docent['sub'] ,
            ':scj': environment.schoolJaar,
            ':gdg' : true
          },
          ExpressionAttributeNames: {
            '#key' : 'SubId',
            '#sortkey' : 'SchoolJaar'
          },
         // ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,ID',
          ExclusiveStartKey :startkey
        }          
        let items = [];
       
          while (true) {
        const db = await this.getDC();
        const data = await (db as DocumentClient).query(params).promise();
        items = items.concat(data.Items);
    
       if (! data.LastEvaluatedKey) {
         break;
       }
       
       params.ExclusiveStartKey = data.LastEvaluatedKey;
     }
     
     return _.sortBy(items, 'TaakNaam');
      
        };

      async verwijderToetsMijnKlas(toetsen){

          const mytoetsen = await _.map(toetsen, function (toets) {
            return _.omit(toets,
            ['Geldig','StudentId','SubId','Achternaam', 'Voornamen','TaakNaam','Timestamp','ToetsNaam','Vr1','Vr2','Vr3','Vr4','Vr5','Vr6','Vr7','Vr8','Vr9','Vr10','Vr11','Vr12','Vr13']);
        });
        const dynamoDb = await  this.getDC();

       
        let promises;
          
        promises = toetsen.map(function (toetsen) {
    
           
            const params = {
              TableName: environment.ddbCijfer,
                Key: {
                  'ToetsID' : toetsen.ToetsID,
                  'SchoolJaar': toetsen.SchoolJaar 
                },
    
            };
            return (dynamoDb as DocumentClient).delete(params).promise();
        });

        return Promise.all(promises).then(function (responses: any) {
          return responses;
      
      })
         
      }

      async getSchoolbestuurDOSUit(){
        //Uitgeschreven van SchoolBestuur DOS 
       let startkey: any;
 
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'DOS' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
         ExclusiveStartKey :startkey
       }          
       let items = [];
      
         while (true) {
       const db = await this.getDC();
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
  
      if (! data.LastEvaluatedKey) {
        break;
      }
 
      params.ExclusiveStartKey = data.LastEvaluatedKey;
    }
    return _.sortBy(items, 'Achternaam');
     
    };


    async getSchoolbestuurCOMUit(){
      //Uitgeschreven van SchoolBestuur COM 
     let startkey: any;

     let params = {
       TableName: environment.ddbName,
       IndexName: environment.ddbNameIndex1 ,
       KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
       FilterExpression : 'Ingeschreven = :ing' ,
       ExpressionAttributeValues: {
         ':sbs': 'COM' ,
         ':scj': environment.schoolJaar,
         ':ing' : false
       },
       ExpressionAttributeNames: {
         '#key' : 'SchoolBestuur',
         '#sortkey' : 'SchoolJaar'
       },
       ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
       ExclusiveStartKey :startkey
     }          
     let items = [];
    
       while (true) {
     const db = await this.getDC();
     const data = await (db as DocumentClient).query(params).promise();
     items = items.concat(data.Items);

    if (! data.LastEvaluatedKey) {
      break;
    }

    params.ExclusiveStartKey = data.LastEvaluatedKey;
  }
  return _.sortBy(items, 'Achternaam');
   
  };

  async getSchoolbestuurEBGUit(){
    //Uitgeschreven van SchoolBestuur EBG
   let startkey: any;

   let params = {
     TableName: environment.ddbName,
     IndexName: environment.ddbNameIndex1 ,
     KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
     FilterExpression : 'Ingeschreven = :ing' ,
     ExpressionAttributeValues: {
       ':sbs': 'EBG' ,
       ':scj': environment.schoolJaar,
       ':ing' : false
     },
     ExpressionAttributeNames: {
       '#key' : 'SchoolBestuur',
       '#sortkey' : 'SchoolJaar'
     },
     ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
     ExclusiveStartKey :startkey
   }          
   let items = [];
  
     while (true) {
   const db = await this.getDC();
   const data = await (db as DocumentClient).query(params).promise();
   items = items.concat(data.Items);

  if (! data.LastEvaluatedKey) {
    break;
  }

  params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');
 
};

async getSchoolbestuurESBUit(){
  //Uitgeschreven van SchoolBestuur ESB
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'ESB' ,
     ':scj': environment.schoolJaar,
     ':ing' : false
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');

};

async getSchoolbestuurFSHPUit(){
  //Uitgeschreven van SchoolBestuur FSHP
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'FSHP' ,
     ':scj': environment.schoolJaar,
     ':ing' : false
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');

};

async getSchoolbestuurMBCBUit(){
  //Uitgeschreven van SchoolBestuur MBCB
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'MBCB' ,
     ':scj': environment.schoolJaar,
     ':ing' : false
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');

};

async getSchoolbestuurRAKUit(){
  //Uitgeschreven van SchoolBestuur RAK
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'RAK' ,
     ':scj': environment.schoolJaar,
     ':ing' : false
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');

};

async getSchoolbestuurRKCSUit(){
  //Uitgeschreven van SchoolBestuur RKCS
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'RKCS' ,
     ':scj': environment.schoolJaar,
     ':ing' : false
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');

};

async getSchoolbestuurSCONSUit(){
  //Uitgeschreven van SchoolBestuur SCONS
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'SCONS' ,
     ':scj': environment.schoolJaar,
     ':ing' : false
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');

};

async getSchoolbestuurSOZDAUit(){
  //Uitgeschreven van SchoolBestuur SOZDA
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'SOZDA' ,
     ':scj': environment.schoolJaar,
     ':ing' : false
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');

};

async getSchoolbestuurVPCOUit(){
  //Uitgeschreven van SchoolBestuur VPCO
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'VPCO' ,
     ':scj': environment.schoolJaar,
     ':ing' : false
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');

};

async getSchoolbestuurDEMUit(){
  //Uitgeschreven van SchoolBestuur DEM
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'DEM' ,
     ':scj': environment.schoolJaar,
     ':ing' : false
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurPIPBUit(){
  //Uitgeschreven van SchoolBestuur PIPB
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'PIPB' ,
     ':scj': environment.schoolJaar,
     ':ing' : false
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurSNSUit(){
  //Uitgeschreven van SchoolBestuur SNS
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'SNS' ,
     ':scj': environment.schoolJaar,
     ':ing' : false
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurKCBUit(){
  //Uitgeschreven van SchoolBestuur KCB
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'KCB' ,
     ':scj': environment.schoolJaar,
     ':ing' : false
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,SchoolJaar,ID,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'Achternaam');

};


async getAlleLeerlingenUit(){
  //Uitgeschreven alle leerlingen



  let DOS = await this.getSchoolbestuurDOSUit();
  let COM = await this.getSchoolbestuurCOMUit();
  let EBG = await this.getSchoolbestuurEBGUit();
  let ESB = await this.getSchoolbestuurESBUit();
  let FSHP = await this.getSchoolbestuurFSHPUit();
  let MBCB = await this.getSchoolbestuurMBCBUit();
  let RAK = await this.getSchoolbestuurRAKUit();
  let RKCS = await this.getSchoolbestuurRKCSUit();
  let SCONS = await this.getSchoolbestuurSCONSUit();
  let SOZDA = await this.getSchoolbestuurSOZDAUit();
  let VPCO = await this.getSchoolbestuurVPCOUit();
  let DEM = await this.getSchoolbestuurDEMUit();
  let PIPB = await this.getSchoolbestuurPIPBUit();
  let SNS = await this.getSchoolbestuurSNSUit();
  let KCB = await this.getSchoolbestuurKCBUit();

  let alles = await  DOS.concat(COM, EBG ,ESB , FSHP , MBCB ,RAK , RKCS , SCONS , SOZDA , VPCO , DEM ,PIPB , SNS , KCB);

  return alles ;

};

async getIngeschrevenDOS(){
  //get alle ingeschreven leerlingen van SchoolBestuur DOS 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   //FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'DOS' ,
     ':scj': environment.schoolJaar
    // ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};

async getIngeschrevenDEM(){
  //get alle ingeschreven leerlingen van SchoolBestuur DEM 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   //FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'DEM' ,
     ':scj': environment.schoolJaar
    // ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};

async getIngeschrevenCOM(){
  //get alle ingeschreven leerlingen van SchoolBestuur COM 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   //FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'COM' ,
     ':scj': environment.schoolJaar
    // ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};

async getIngeschrevenEBG(){
  //get alle ingeschreven leerlingen van SchoolBestuur EBG 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   //FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'EBG' ,
     ':scj': environment.schoolJaar
    // ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};

async getIngeschrevenESB(){
  //get alle ingeschreven leerlingen van SchoolBestuur ESB 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
  // FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'ESB' ,
     ':scj': environment.schoolJaar
    // ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};


async getIngeschrevenFSHP(){
  //get alle ingeschreven leerlingen van SchoolBestuur FSHP 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
  // FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'FSHP' ,
     ':scj': environment.schoolJaar
    // ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};


async getIngeschrevenMBCB(){
  //get alle ingeschreven leerlingen van SchoolBestuur MBCB 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
  // FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'MBCB' ,
     ':scj': environment.schoolJaar
   //  ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};

async getIngeschrevenRAK(){
  //get alle ingeschreven leerlingen van SchoolBestuur RAK 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
  // FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'RAK' ,
     ':scj': environment.schoolJaar
    // ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
const docent = await this.cgn.getUserInfo() ;




const message = {
  channel: '#fovt-web-app-errors',
  attachments: [
    {
        author_name: window.location.href,
        color: 'danger',
        title: 'Trace',
        username:docent['sub'] ,
        text: 'nada'
    }
]
}
const options = {
  headers: new HttpHeaders(
    { 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8' } 
  )
};
this.http.post(environment.slackError, message, options).subscribe();

return items;

};


async getIngeschrevenRKCS(){
  //get alle ingeschreven leerlingen van SchoolBestuur RKCS 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
  // FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'RKCS' ,
     ':scj': environment.schoolJaar
   //  ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};


async getIngeschrevenSCONS(){
  //get alle ingeschreven leerlingen van SchoolBestuur SCONS 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   //FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'SCONS' ,
     ':scj': environment.schoolJaar
     //':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};


async getIngeschrevenSOZDA(){
  //get alle ingeschreven leerlingen van SchoolBestuur SOZDA 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   //FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'SOZDA' ,
     ':scj': environment.schoolJaar
    // ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};


async getIngeschrevenVPCO(){
  //get alle ingeschreven leerlingen van SchoolBestuur VPCO 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
  // FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'VPCO' ,
     ':scj': environment.schoolJaar
    // ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};

async getIngeschrevenPIPB(){
  //get alle ingeschreven leerlingen van SchoolBestuur PIPB 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
  // FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'PIPB' ,
     ':scj': environment.schoolJaar
    // ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};

async getIngeschrevenSNS(){
  //get alle ingeschreven leerlingen van SchoolBestuur SNS 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
  // FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'SNS' ,
     ':scj': environment.schoolJaar
    // ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};

async getIngeschrevenKCB(){
  //get alle ingeschreven leerlingen van SchoolBestuur KCB 
 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
  // FilterExpression : 'Ingeschreven = :ing' ,
   ExpressionAttributeValues: {
     ':sbs': 'KCB' ,
     ':scj': environment.schoolJaar
    // ':ing' : true
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolNr,GroepNr,KlasNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};

async getAlleLeerlingenIN(){ 
    //Alle ingeschreven leerlingen

    let  [DOS, COM,EBG,ESB,FSHP,MBCB,RAK,RKCS,SCONS,SOZDA,VPCO,DEM ,PIPB ,SNS , KCB] = await Promise.all([this.getIngeschrevenDOS(), this.getIngeschrevenCOM(),
    this.getIngeschrevenEBG(),this.getIngeschrevenESB(),this.getIngeschrevenFSHP(),this.getIngeschrevenMBCB(),this.getIngeschrevenRAK(),
    this.getIngeschrevenRKCS(),this.getIngeschrevenSCONS(),this.getIngeschrevenSOZDA(),this.getIngeschrevenVPCO(),this.getIngeschrevenDEM(),
    this.getIngeschrevenPIPB(),this.getIngeschrevenSNS(),this.getIngeschrevenKCB()]);



    let alles = await DOS.concat(COM, EBG ,ESB , FSHP , MBCB ,RAK , RKCS , SCONS , SOZDA , VPCO , DEM ,PIPB ,SNS ,KCB);





    return alles;
}




async getAlleResultatenE3(){
  //get alle resultaten van toets E3 
 let startkey: any;
 
 let params = {
   TableName: environment.ddbCijfer,
   IndexName: environment.ddbCijferToets ,
   KeyConditionExpression : '#key = :tts and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':tts': 'E3' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'ToetsNaam',
     '#sortkey' : 'SchoolJaar',
     '#tmstamp' :'Timestamp'
   },
   ProjectionExpression: 'StudentId,SubId,#tmstamp,ToetsNaam,TaakNaam,Vr1,Vr2,Vr3,Vr4,Vr5,Vr6,Vr7,Vr8,Vr9,Vr10,Vr11,Vr12,Vr13',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};


async getAlleResultatenE4(){
  //get alle resultaten van toets E4
 let startkey: any;
 
 let params = {
   TableName: environment.ddbCijfer,
   IndexName: environment.ddbCijferToets ,
   KeyConditionExpression : '#key = :tts and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':tts': 'E4' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'ToetsNaam',
     '#sortkey' : 'SchoolJaar',
     '#tmstamp' :'Timestamp'

   },
   ProjectionExpression: 'StudentId,SubId,#tmstamp,ToetsNaam,TaakNaam,Vr1,Vr2,Vr3,Vr4,Vr5,Vr6,Vr7,Vr8,Vr9,Vr10,Vr11,Vr12,Vr13',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};


async getAlleResultatenE5(){
  //get alle resultaten van toets E5
 let startkey: any;
 
 let params = {
   TableName: environment.ddbCijfer,
   IndexName: environment.ddbCijferToets ,
   KeyConditionExpression : '#key = :tts and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':tts': 'E5' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'ToetsNaam',
     '#sortkey' : 'SchoolJaar',
     '#tmstamp' :'Timestamp'
   },
   ProjectionExpression: 'StudentId,SubId,#tmstamp,ToetsNaam,TaakNaam,Vr1,Vr2,Vr3,Vr4,Vr5,Vr6,Vr7,Vr8,Vr9,Vr10,Vr11,Vr12,Vr13',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;



};

async getAlleResultatenE6(){
  //get alle resultaten van toets E6
 let startkey: any;
 
 let params = {
   TableName: environment.ddbCijfer,
   IndexName: environment.ddbCijferToets ,
   KeyConditionExpression : '#key = :tts and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':tts': 'E6' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'ToetsNaam',
     '#sortkey' : 'SchoolJaar',
     '#tmstamp' :'Timestamp'

   },
   ProjectionExpression: 'StudentId,SubId,#tmstamp,ToetsNaam,TaakNaam,Vr1,Vr2,Vr3,Vr4,Vr5,Vr6,Vr7,Vr8,Vr9,Vr10,Vr11,Vr12,Vr13',
   ExclusiveStartKey :startkey
 }     
 
 try {
 let items = [];



   while (true) {

    
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);
    
    

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
   
return items;
 }
 catch (err){

  console.log(err);

 }

};


async getAlleResultatenE7(){
  //get alle resultaten van toets E7
 let startkey: any;
 
 let params = {
   TableName: environment.ddbCijfer,
   IndexName: environment.ddbCijferToets ,
   KeyConditionExpression : '#key = :tts and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':tts': 'E7' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'ToetsNaam',
     '#sortkey' : 'SchoolJaar',
     '#tmstamp' :'Timestamp'

   },
   ProjectionExpression: 'StudentId,SubId,#tmstamp,ToetsNaam,TaakNaam,Vr1,Vr2,Vr3,Vr4,Vr5,Vr6,Vr7,Vr8,Vr9,Vr10,Vr11,Vr12,Vr13',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}

return items;

};



async getAlleResultatenEToetsen(){
    //Alle Resultaten van E3,E4,E5,E6,E7 Samen



 let  [E3, E4,E5,E6,E7] = await Promise.all([this.getAlleResultatenE3(), this.getAlleResultatenE4(),this.getAlleResultatenE5(),this.getAlleResultatenE6(),await this.getAlleResultatenE7()]);

 



  let alles = await  E3.concat(E4,E5,E6,E7);
    return alles ;

}

async getAlleAWB(){
  
  let [a1,a2] = await Promise.all([this.getAlleResultatenEToetsen(),this.getAlleLeerlingenIN()]);
  let result = a1.slice(0);

  for (var i = 0 ; i < result.length ; i++){
    for (var j = 0; j < a2.length ; j++){
      if (result[i].StudentId == a2[j].StudentId){
        result[i].SchoolNr = a2[j].SchoolNr;
        result[i].GroepNr = a2[j].GroepNr;
        result[i].KlasNr = a2[j].KlasNr;
        result[i].Achternaam = a2[j].Achternaam;
        result[i].Voornamen = a2[j].Voornamen;
  
  
      }
    };  
  };
  
  console.log(result)
 

  return result ;

}


async getKlasRapportage(){
    //Get aantal leerlingen per klas 
      //Get aantaal leerlingen per klas plus aantal ingevulde AWB en aantal nog in te vullen

     let [a1,a2] = await Promise.all([this.getAlleResultatenEToetsen(),this.getAlleLeerlingenIN()]);
     console.log(a1);
     console.log(a2);

  
    let result = a1.slice(0);

for (var i = 0 ; i < result.length ; i++){
  for (var j = 0; j < a2.length ; j++){
    if (result[i].StudentId == a2[j].StudentId){
      result[i].SchoolNr = a2[j].SchoolNr;
      result[i].GroepNr = a2[j].GroepNr;
      result[i].KlasNr = a2[j].KlasNr;


    }
  };  
};

let toetsklas = await result.reduce( (acc, o) => (acc[o.SchoolNr + o.GroepNr + o.KlasNr] = (acc[o.SchoolNr + o.GroepNr + o.KlasNr] || 0)+1, acc), {} );
let leerlingenklas = await a2.reduce((acc,o) =>(acc[o.SchoolNr + o.GroepNr + o.KlasNr] = (acc[o.SchoolNr + o.GroepNr + o.KlasNr] || 0)+1, acc), {} );


let groepInfo = [];
let toetsInfo =[];

await Object.keys(leerlingenklas).forEach(function(key) {
  if (key.length === 6 ){
    let bon = key.match(/.{1,2}/g);
    if (key.slice(3,4) ===  "3"){
    groepInfo.push({SchoolNr : bon[0], GroepNr : bon[1] , KlasNr : bon[2], AantalLL : leerlingenklas[key] , voorspellingAWB : leerlingenklas[key] * 5 });
    }
    else if (key.slice(3,4) ===  "4"){
    groepInfo.push({SchoolNr : bon[0], GroepNr : bon[1] , KlasNr : bon[2], AantalLL : leerlingenklas[key] , voorspellingAWB : leerlingenklas[key] * 6 });
    }
    else if (key.slice(3,4) ===  "5"){
    groepInfo.push({SchoolNr : bon[0], GroepNr : bon[1] , KlasNr : bon[2], AantalLL : leerlingenklas[key] , voorspellingAWB : leerlingenklas[key] * 8 });
    }
    else if (key.slice(3,4) ===  "6"){
    groepInfo.push({SchoolNr : bon[0], GroepNr : bon[1] , KlasNr : bon[2], AantalLL : leerlingenklas[key] , voorspellingAWB : leerlingenklas[key] * 9 });
    }
    else if (key.slice(3,4) ===  "7"){
    groepInfo.push({SchoolNr : bon[0], GroepNr : bon[1] , KlasNr : bon[2], AantalLL : leerlingenklas[key] , voorspellingAWB : leerlingenklas[key] * 9 });
    }
    else{
      // Hier zijn leerlingen die niet in gr 3/4/5/6/7 zijn
    console.log('Deze leerling is niet in een groep tussen 3 en 7');
    console.log(key, leerlingenklas[key]);
    }
  }
  else {
    // Hier zijn leerlingen zonder klas of school of groep
    console.log('Er zijn leerlingen zonder klas of groep of school');
    console.log(key, leerlingenklas[key]);
  }
});
console.log(groepInfo);
await Object.keys(toetsklas).forEach(function(key) {
  if (key.length === 6 ){
    let bon = key.match(/.{1,2}/g);
    if (key.slice(3,4) ===  "3"){
    toetsInfo.push({SchoolNr : bon[0], GroepNr : bon[1] , KlasNr : bon[2], AWB : toetsklas[key]});
    }
    else if (key.slice(3,4) ===  "4"){
    toetsInfo.push({SchoolNr : bon[0], GroepNr : bon[1] , KlasNr : bon[2], AWB : toetsklas[key]});
    }
    else if (key.slice(3,4) ===  "5"){
    toetsInfo.push({SchoolNr : bon[0], GroepNr : bon[1] , KlasNr : bon[2], AWB : toetsklas[key]});
    }
    else if (key.slice(3,4) ===  "6"){
    toetsInfo.push({SchoolNr : bon[0], GroepNr : bon[1] , KlasNr : bon[2], AWB : toetsklas[key]});
    }
    else if (key.slice(3,4) ===  "7"){
    toetsInfo.push({SchoolNr : bon[0], GroepNr : bon[1] , KlasNr : bon[2], AWB : toetsklas[key]});
    }
    else{
      // Hier zijn leerlingen die niet in gr 3/4/5/6/7 zijn maar hebben wel cijfers
    console.log('Deze leerling is niet in een groep tussen 3 en 7 maar hebben wel cijfers');
    console.log(key, leerlingenklas[key]);
    }
  }
  else {
    // Hier zijn leerlingen zonder klas of school of groep maar hebben wel cijfers
    console.log('Er zijn leerlingen zonder klas of groep of school maar hebben wel cijfers');
    console.log(key, leerlingenklas[key]);
  }
});
console.log(toetsInfo);

let raport = groepInfo.slice(0);

for (var i = 0 ; i < raport.length ; i++){
  for (var j = 0; j < toetsInfo.length ; j++){
    if (raport[i].SchoolNr == toetsInfo[j].SchoolNr && raport[i].GroepNr == toetsInfo[j].GroepNr && raport[i].KlasNr == toetsInfo[j].KlasNr){
      raport[i].AWB = toetsInfo[j].AWB;
      raport[i].Falta =  raport[i].voorspellingAWB - toetsInfo[j].AWB;

      


    }
  };  
};


console.log(raport);

return raport;

}

getKlasRapportageLambda(){


  const params = {
    FunctionName : 'rapportetoetsen',
    InvocationType : 'RequestResponse',
    Payload : environment.schoolJaar ,
    LogType : 'None'

  };

return  this.getLC()
.then(client  => (client as Lambda).invoke(params).promise())
.then(data => { return data.Payload})
.catch(err =>{ 
  const message = {
    channel: '#fovt-web-app-errors',
    attachments: [
      {
          author_name: window.location.href,
          color: 'danger',
          title: 'Trace',
          username:'',
          text: err
      }
  ]
  }
  
  const options = {
    headers: new HttpHeaders(
      { 'Content-Type': 'application/x-www-form-urlencoded' }
    )
  };
  this.http.post(environment.slackError, message, options).subscribe();
  
  console.log(err)});
}

async getMijnSchoolAllesVJ(){
  //Alle Leerlingen van mijn school tussen 1 lagere groepnr en mijn groepnr die vorige jaar 
  //daar was ingeschreven en uitgeschreven die niet naar een andere school waren gegaan
  //en die geen attribute NiewSJ hebben

 const docent = await this.cgn.getUserInfo() ;
 let vklas = await docent['groepnr'] -1 ;
 let vorigeklas = '0' + vklas;

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'SchoolNr = :snr and GroepNr between :vgnr and :gnr  and KlasNr > :knr and attribute_not_exists(#nsj)' , 
   ExpressionAttributeValues: {
     ':sbs': docent['schoolbestuur'] ,
     ':scj': environment.vorigeSchoolJaar,
     ':snr': docent['schoolnr'] ,
     ':vgnr': vorigeklas ,
     ':gnr': docent['groepnr'],
     ':knr' : '0'
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar',
     '#nsj' :'NieuwSJ'

   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,GroepNr,KlasNr,ID',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'GroepNr');
 
};

async getEenSchoolAllesVJ(dataSBS){
  //Alle Leerlingen van gekozen school tussen 1 lagere groepnr en mijn groepnr die vorige jaar 
  //daar was ingeschreven en uitgeschreven die niet naar een andere school waren gegaan
  //en die geen attribute NiewSJ hebben  

 const docent = await this.cgn.getUserInfo() ;
 let vklas = await docent['groepnr'] -1 ;
 let vorigeklas = '0' + vklas;

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   FilterExpression : 'SchoolNr = :snr and GroepNr between :vgnr and :gnr  and KlasNr > :knr and attribute_not_exists(#nsj)' , 
   ExpressionAttributeValues: {
     ':sbs': dataSBS['SchoolBestuur'] ,
     ':scj': environment.vorigeSchoolJaar,
     ':snr': dataSBS['SchoolNr'] ,
     ':vgnr': vorigeklas ,
     ':gnr': docent['groepnr'],
     ':knr' : '0'
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar',
     '#nsj' :'NieuwSJ'

   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,Geboortedatum,GroepNr,KlasNr,ID',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
return _.sortBy(items, 'GroepNr');
 
};



async vjUpdateEnAddStudentMijnKlas(students){
  //Nieuwjaar update oude student met  item attribute NieuwsJ = true zodat we weten welke studenten zijn gemaakt voor de volgende schooljaar
  // en ook maak een copy van oude student met nieuwe schooljaar en met klasnr/schoolnr/groepnr/schoolbestuur van docent en ingerschreven = true

  const dynamoDb = await this.getDC();
  let promises;
  const docent =  await this.cgn.getUserInfo();

  students.forEach(function(item) {
    item.SchoolJaar = environment.schoolJaar;
    item.Ingeschreven = true;
    item.KlasNr = docent['klasnr'];
    item.GroepNr = docent['groepnr'];
    item.SchoolBestuur = docent['schoolbestuur'];
    item.SchoolNr = docent['schoolnr'];
  });
  
  promises = students.map(function (Item) {
    
    let params = {
      TransactItems: [{
        Put: {
          TableName: environment.ddbName,
          IndexName: environment.ddbNameIndex1,
          Item ,
          ConditionExpression: 'attribute_not_exists(StudentId) AND attribute_not_exists(SchoolJaar)'
        }
      }, {
        Update: {
          TableName: environment.ddbName,
          IndexName: environment.ddbNameIndex1,
          Key: { 'StudentId' : Item.StudentId,'SchoolJaar' : environment.vorigeSchoolJaar 
          },
          UpdateExpression: 'set NieuwSJ = :nsj',
          ConditionExpression: 'attribute_exists(StudentId) AND attribute_exists(SchoolJaar)AND attribute_not_exists(NieuwSJ)',
          ExpressionAttributeValues: {
            ':nsj': true
          }
        }
      }]
    };
    return (dynamoDb as DocumentClient).transactWrite(params).promise();

});
return Promise.all(promises).then(data => { 
  return data ;     
})
.catch(function(err) {
    console.log(err);
});

}




//   QUERIES FOR ELKE
//     

async getSchoolbestuurDOSAantal(){
  //Aantal leerlingen van SchoolBestuur ESB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'DOS' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurCOMAantal(){
  //Aantal leerlingen van SchoolBestuur ESB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'COM' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurEBGAantal(){
  //Aantal leerlingen van SchoolBestuur ESB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'EBG' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurESBAantal(){
  //Aantal leerlingen van SchoolBestuur ESB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'ESB' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');


};


async getSchoolbestuurFSHPAantal(){
  //Aantal leerlingen van SchoolBestuur ESB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'FSHP' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};

async getSchoolbestuurMBCBAantal(){
  //Aantal leerlingen van SchoolBestuur ESB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'MBCB' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurRAKAantal(){
  //Aantal leerlingen van SchoolBestuur ESB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'RAK' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurRKCSAantal(){
  //Aantal leerlingen van SchoolBestuur ESB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'RKCS' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurSOZDAAantal(){
  //Aantal leerlingen van SchoolBestuur ESB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'SOZDA' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurSCONSAantal(){
  //Aantal leerlingen van SchoolBestuur ESB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'SCONS' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};

async getSchoolbestuurVPCOAantal(){
  //Aantal leerlingen van SchoolBestuur ESB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'VPCO' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurDEMAantal(){
  //Aantal leerlingen van SchoolBestuur DEM die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'DEM' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurPIPBAantal(){
  //Aantal leerlingen van SchoolBestuur PIPB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'PIPB' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurSNSAantal(){
  //Aantal leerlingen van SchoolBestuur SNS die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'SNS' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};


async getSchoolbestuurKCBAantal(){
  //Aantal leerlingen van SchoolBestuur KCB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

 let startkey: any;

 let params = {
   TableName: environment.ddbName,
   IndexName: environment.ddbNameIndex1 ,
   KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
   ExpressionAttributeValues: {
     ':sbs': 'KCB' ,
     ':scj': environment.schoolJaar,
   },
   ExpressionAttributeNames: {
     '#key' : 'SchoolBestuur',
     '#sortkey' : 'SchoolJaar'
   },
   ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
   ExclusiveStartKey :startkey
 }          
 let items = [];

   while (true) {
 const db = await this.getDC();
 const data = await (db as DocumentClient).query(params).promise();
 items = items.concat(data.Items);

if (! data.LastEvaluatedKey) {
  break;
}

params.ExclusiveStartKey = data.LastEvaluatedKey;
}
console.log(items);
return _.sortBy(items, 'Achternaam');

};


async getAlleLeerlingen2020(){ 
  //Alle ingeschreven leerlingen

  let  [DOS, COM,EBG,FSHP,MBCB,RAK,RKCS,SCONS,SOZDA,VPCO,DEM ,PIPB , SNS , KCB] = await Promise.all([this.getSchoolbestuurDOSAantal(),this.getSchoolbestuurCOMAantal(),this.getSchoolbestuurEBGAantal(),
  this.getSchoolbestuurFSHPAantal(),this.getSchoolbestuurMBCBAantal(),this.getSchoolbestuurRAKAantal(),this.getSchoolbestuurRKCSAantal(), this.getSchoolbestuurSCONSAantal(), this.getSchoolbestuurSOZDAAantal(),
   this.getSchoolbestuurVPCOAantal(), this.getSchoolbestuurDEMAantal() , this.getSchoolbestuurPIPBAantal() , this.getSchoolbestuurSNSAantal() , this.getSchoolbestuurKCBAantal() ]);


  let alles = await DOS.concat(COM, EBG  , FSHP , MBCB ,RAK , RKCS , SCONS , SOZDA , VPCO , DEM , PIPB , SNS , KCB);





 return alles;
};

getLambda(){


  const params = {
    FunctionName : 'ResultatenM6-7ToestenMetNamen',
    InvocationType : 'RequestResponse',
    Payload : environment.schoolJaar ,
    LogType : 'None'

  };

return  this.getLC()
.then(client  => (client as Lambda).invoke(params).promise())
.then(data => { 
  console.log(data.Payload);
  return data.Payload

})
.catch(err =>{ 

 
  console.log(err)});
}


 async getLeerlingprofiel(formvalue){

  const params = {
    FunctionName : 'Leerlingprofiel',
    InvocationType : 'RequestResponse',
    Payload : JSON.stringify(formvalue) ,
    LogType : 'None'
  };

  try {

    const client = await this.getLC();
    const data = await (client as Lambda).invoke(params).promise();
    return await JSON.parse(JSON.parse(JSON.stringify(data.Payload)));
    
  } catch (error) {
    console.log(error);    
  }
 }

 async getGroepsprofiel(formvalue , students){

  let mydata = [formvalue , students];
  const params = {
    FunctionName : 'Groepsprofiel',
    InvocationType : 'RequestResponse',
    Payload : JSON.stringify(mydata) ,
    LogType : 'None'
  };

  try {

    const client = await this.getLC();
    const data = await (client as Lambda).invoke(params).promise();
    return await JSON.parse(JSON.parse(JSON.stringify(data.Payload)));
    
  } catch (error) {
    console.log(error);    
  }
 }


    }
