import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Intercom} from 'ng-intercom';
import {AmplifyService} from 'aws-amplify-angular';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  title = 'ETE WebApp';

  constructor(private router: Router, public intercom: Intercom, private amplify: AmplifyService) {

    this.amplify.authStateChange$
            .subscribe(authState => {


              if (authState.state === 'signedIn'){

                if (!authState.user) {}
                else{
                  console.log('yo');
                  this.intercom.boot({
                    ...authState.user.attributes,
                    widget: {
                      'activator': '#intercom'
                    }
                  });
                }
              }
              else if (authState.state === 'signedOut'){
                this.intercom.shutdown();
                console.log('afoo');

              }

        });

       
  }

  

  ngOnInit() {
    // get current user and adds it to intercom conf.
    


    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}