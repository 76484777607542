import { Injectable } from '@angular/core';
import { DynamoDBFilter } from './dynamodbfilter';


@Injectable()
export class DynamoDBFilterService {

  getPersoonFilters() {
    return [
     new DynamoDBFilter( 'Voornaam','contains(Voornamen, :qyf)' ),
     new DynamoDBFilter( 'Achternaam','contains(Achternaam, :qyf)' ),
     new DynamoDBFilter( 'Geboortedatum','contains(Geboortedatum, :qyf)' ),
     new DynamoDBFilter( 'ID','contains(ID, :qyf)' )

    ];
  }





}
