import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import { Http } from '@angular/http';
import { Config } from './config';
import { map } from "rxjs/operators";





@Injectable()
export class ConfigService {

    private config: Config;



    constructor(private http: Http) { }

    

    load(url: string) {
        return new Promise((resolve) => {
          this.http.get(url).pipe(map(res => res.json()))
            .subscribe(config => {
              this.config = config;
              resolve();
            });
        });
      }
    
      getConfiguration(): Config {
        return this.config;
      }

}


