import {Injectable} from "@angular/core";
import { Auth, Logger } from 'aws-amplify';
import {environment} from "../../environments/environment";
import { _ } from 'underscore';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import * as SES from "aws-sdk/clients/ses";
import {HelperService} from "./helper.service";
import {CognitoService} from "./cognito.service";
import * as Lambda from "aws-sdk/clients/lambda";

const logger = new Logger('DynamoDBAdminService');



@Injectable()
export class DynamoDBHoofdService { 

    constructor(public hps: HelperService, public cgn: CognitoService ) { }

    async getDC() {
        return Auth.currentCredentials()
          .then(credentials => new DocumentClient({ credentials: credentials , region: environment.region }))  //correctClockSkew: true,
          .catch(err => logger.debug('error getting document client', err));
      };


     
      async getSchoolbestuurAantal(){

        let schoolbesturen = ['DOS','COM','EBG','ESB','FSHP','MBCB','RAK','RKCS','SCONS','SOZDA','VPCO','DEM'];
        const db = await this.getDC();

        return Promise.all( schoolbesturen.map(function (sb) {
          let startkey;
          let prome = [];
          let items = [];
          let params = {
               TableName: environment.ddbName,
               IndexName: environment.ddbNameIndex1 ,
               KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
               FilterExpression : 'attribute_not_exists(#nsj)' ,
               ExpressionAttributeValues: {
                 ':sbs': sb ,
                 ':scj': environment.vorigeSchoolJaar,
               },
               ExpressionAttributeNames: {
                 '#key' : 'SchoolBestuur',
                 '#sortkey' : 'SchoolJaar',
                 '#nsj' :'NieuwSJ'
               },
               ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
               ExclusiveStartKey :startkey
             }


             function next() {
        
            
               return (db as DocumentClient).query(params).promise().then(function(result) {
                    
                    prome = result.Items;

                if (typeof result.LastEvaluatedKey != "undefined") {
                  params.ExclusiveStartKey = result.LastEvaluatedKey;
                  items = items.concat(result.Items);

                  return next();
             } else {
                  items = items.concat(prome);


                  return items;
             }
            
            });
        
            }

            return next().then(function(result) {

              return result;

        }).catch(function(err) {
          console.log(err);
        });

      })).then(function(responses) {

        let merged = [].concat.apply([], responses);
        console.log(merged);
        return merged;

  }).catch(function(err) {
    console.log(err);
  });

      };



      async getSchoolAantalVSJ(){
        //GetAschoolAantalVorigeSchoolJaar
        //Aantal leerlingen van een School die vorige schooljaar waren en deze schooljaar op geen enkele /school van curacao bevinden

       let startkey: any;
       const hoofd = await this.cgn.getUserInfo() ;

      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'SchoolNr = :snr' ,
         ExpressionAttributeValues: {
           ':sbs': hoofd['schoolbestuur'] ,
           ':scj': environment.vorigeSchoolJaar,
           ':snr': hoofd['schoolnr'] ,

         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ProjectionExpression: 'Achternaam ,Voornamen,StudentId,ID,Geboortedatum,SchoolJaar,GroepNr,KlasNr ,NieuwSJ',
         ExclusiveStartKey :startkey
       }          
       let items = [];
      
         while (true) {
       const db = await this.getDC();
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };


      async getSchoolAantal(){
        //GetAschoolAantal
        //Aantal leerlingen van een School die nu zijn ingeschreven op dat school
       let startkey: any;
       const hoofd = await this.cgn.getUserInfo() ;

      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'SchoolNr = :snr and Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': hoofd['schoolbestuur'] ,
           ':scj': environment.schoolJaar,
           ':snr': hoofd['schoolnr'] ,
           ':ing' : true,

         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ProjectionExpression: 'Achternaam ,Voornamen,StudentId,ID,Geboortedatum,SchoolJaar,GroepNr,KlasNr',
         ExclusiveStartKey :startkey
       }          
       let items = [];
      
         while (true) {
       const db = await this.getDC();
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };

    
      async getCountSchoolAantal(){
      //nvj betekent naar volgendjaar 
      const nvj = true;
      let totalVorigJaar =0;
      let totalDezeJaar =0;
      const vjIngeschreven = await this.getSchoolAantalVSJ();
      const nuIngeschreven = await this.getSchoolAantal();

      const nietIn = vjIngeschreven.reduce((acc, cur) => cur.NieuwSJ === nvj ? ++acc : acc, 0);
      const ingeschrevenNJ = parseInt(nietIn);

      totalVorigJaar = vjIngeschreven.length;
      totalDezeJaar = nuIngeschreven.length;

      const nogNietInNJ = totalVorigJaar - ingeschrevenNJ;

      let aantalLLPerKlasVorigeJaar = await vjIngeschreven.reduce((acc,o) =>(acc[o.GroepNr + o.KlasNr] = (acc[o.GroepNr + o.KlasNr] || 0)+1, acc), {} );
      console.log(aantalLLPerKlasVorigeJaar);


      const llPerKlasNaarNiewJaar = vjIngeschreven.filter(obj => obj.NieuwSJ == nvj);
      const llPerKlasNogNietNaarNiewJaar = vjIngeschreven.filter(obj => obj.NieuwSJ !== nvj);

      let aantalLLPerKlasVolgendJaar = await llPerKlasNaarNiewJaar.reduce((acc,o) =>(acc[o.GroepNr + o.KlasNr] = (acc[o.GroepNr + o.KlasNr] || 0)+1, acc), {} );
      console.log(aantalLLPerKlasVolgendJaar);
      let aantalLLPerKlasNogNietNaarVolgendJaar = await llPerKlasNogNietNaarNiewJaar.reduce((acc,o) =>(acc[o.GroepNr + o.KlasNr] = (acc[o.GroepNr + o.KlasNr] || 0)+1, acc), {} );
      console.log(aantalLLPerKlasNogNietNaarVolgendJaar);
      let aantalLLPerKlasDezeJaar = await nuIngeschreven.reduce((acc,o) =>(acc[o.GroepNr + o.KlasNr] = (acc[o.GroepNr + o.KlasNr] || 0)+1, acc), {} );
      console.log(aantalLLPerKlasDezeJaar);

      let groepInfo = [];
      let groepInfo2 = [];
      let groepInfo3 = [];
      let groepInfo4 = [];


      await Object.keys(aantalLLPerKlasVorigeJaar).forEach(function(key) {
          //key.slice(1,2)+"a" maakt van 0401 --> 4a  , 0702 --> 7b
          if (key.slice(3,4) ===  "1"){
            groepInfo.push({Groep : key.slice(1,2)+"a", [environment.vorigeSchoolJaar] : aantalLLPerKlasVorigeJaar[key]});
            }
            else if (key.slice(3,4) ===  "2"){
              groepInfo.push({Groep : key.slice(1,2)+"b", [environment.vorigeSchoolJaar] : aantalLLPerKlasVorigeJaar[key]});
            }
            else if (key.slice(3,4) ===  "3"){
              groepInfo.push({Groep : key.slice(1,2)+"c", [environment.vorigeSchoolJaar] : aantalLLPerKlasVorigeJaar[key]});
            }
            else if (key.slice(3,4) ===  "4"){
              groepInfo.push({Groep : key.slice(1,2)+"d", [environment.vorigeSchoolJaar] : aantalLLPerKlasVorigeJaar[key]});
            }
            else if (key.slice(3,4) ===  "5"){
              groepInfo.push({Groep : key.slice(1,2)+"e", [environment.vorigeSchoolJaar] : aantalLLPerKlasVorigeJaar[key]});
            }
            else if (key.slice(3,4) ===  "6"){
              groepInfo.push({Groep : key.slice(1,2)+"f", [environment.vorigeSchoolJaar] : aantalLLPerKlasVorigeJaar[key]});
            }
            else if (key.slice(3,4) ===  "7"){
              groepInfo.push({Groep : key.slice(1,2)+"g", [environment.vorigeSchoolJaar] : aantalLLPerKlasVorigeJaar[key]});
            }
            else{
              console.log(key + "  iets is niet goed groepnr+klasnr 0304  ");
            }
      });
     console.log(groepInfo);

     await Object.keys(aantalLLPerKlasVolgendJaar).forEach(function(key) {
      //key.slice(1,2)+"a" maakt van 0401 --> 4a  , 0702 --> 7b
      if (key.slice(3,4) ===  "1"){
        groepInfo2.push({Groep : key.slice(1,2)+"a", vjndj : aantalLLPerKlasVolgendJaar[key]});
        }
        else if (key.slice(3,4) ===  "2"){
          groepInfo2.push({Groep : key.slice(1,2)+"b", vjndj : aantalLLPerKlasVolgendJaar[key]});
        }
        else if (key.slice(3,4) ===  "3"){
          groepInfo2.push({Groep : key.slice(1,2)+"c", vjndj : aantalLLPerKlasVolgendJaar[key]});
        }
        else if (key.slice(3,4) ===  "4"){
          groepInfo2.push({Groep : key.slice(1,2)+"d", vjndj : aantalLLPerKlasVolgendJaar[key]});
        }
        else if (key.slice(3,4) ===  "5"){
          groepInfo2.push({Groep : key.slice(1,2)+"e", vjndj : aantalLLPerKlasVolgendJaar[key]});
        }
        else if (key.slice(3,4) ===  "6"){
          groepInfo2.push({Groep : key.slice(1,2)+"f", vjndj : aantalLLPerKlasVolgendJaar[key]});
        }
        else if (key.slice(3,4) ===  "7"){
          groepInfo2.push({Groep : key.slice(1,2)+"g", vjndj : aantalLLPerKlasVolgendJaar[key]});
        }
        else{
          console.log(key + "  iets is niet goed groepnr+klasnr 0304  ");
        }
  });
  console.log(groepInfo2);

  await Object.keys(aantalLLPerKlasNogNietNaarVolgendJaar).forEach(function(key) {
    //key.slice(1,2)+"a" maakt van 0401 --> 4a  , 0702 --> 7b
    if (key.slice(3,4) ===  "1"){
      groepInfo3.push({Groep : key.slice(1,2)+"a", vjn : aantalLLPerKlasNogNietNaarVolgendJaar[key]});
      }
      else if (key.slice(3,4) ===  "2"){
        groepInfo3.push({Groep : key.slice(1,2)+"b", vjn : aantalLLPerKlasNogNietNaarVolgendJaar[key]});
      }
      else if (key.slice(3,4) ===  "3"){
        groepInfo3.push({Groep : key.slice(1,2)+"c", vjn : aantalLLPerKlasNogNietNaarVolgendJaar[key]});
      }
      else if (key.slice(3,4) ===  "4"){
        groepInfo3.push({Groep : key.slice(1,2)+"d", vjn : aantalLLPerKlasNogNietNaarVolgendJaar[key]});
      }
      else if (key.slice(3,4) ===  "5"){
        groepInfo3.push({Groep : key.slice(1,2)+"e", vjn : aantalLLPerKlasNogNietNaarVolgendJaar[key]});
      }
      else if (key.slice(3,4) ===  "6"){
        groepInfo3.push({Groep : key.slice(1,2)+"f", vjn : aantalLLPerKlasNogNietNaarVolgendJaar[key]});
      }
      else if (key.slice(3,4) ===  "7"){
        groepInfo3.push({Groep : key.slice(1,2)+"g", vjn : aantalLLPerKlasNogNietNaarVolgendJaar[key]});
      }
      else{
        console.log(key + "  iets is niet goed groepnr+klasnr 0304  ");
      }
});

console.log(groepInfo3);

await Object.keys(aantalLLPerKlasDezeJaar).forEach(function(key) {
    //key.slice(1,2)+"a" maakt van 0401 --> 4a  , 0702 --> 7b
    if (key.slice(3,4) ===  "1"){
      groepInfo4.push({Groep : key.slice(1,2)+"a", [environment.schoolJaar] : aantalLLPerKlasDezeJaar[key]});
      }
      else if (key.slice(3,4) ===  "2"){
        groepInfo4.push({Groep : key.slice(1,2)+"b", [environment.schoolJaar] : aantalLLPerKlasDezeJaar[key]});
      }
      else if (key.slice(3,4) ===  "3"){
        groepInfo4.push({Groep : key.slice(1,2)+"c", [environment.schoolJaar] : aantalLLPerKlasDezeJaar[key]});
      }
      else if (key.slice(3,4) ===  "4"){
        groepInfo4.push({Groep : key.slice(1,2)+"d", [environment.schoolJaar] : aantalLLPerKlasDezeJaar[key]});
      }
      else if (key.slice(3,4) ===  "5"){
        groepInfo4.push({Groep : key.slice(1,2)+"e", [environment.schoolJaar] : aantalLLPerKlasDezeJaar[key]});
      }
      else if (key.slice(3,4) ===  "6"){
        groepInfo4.push({Groep : key.slice(1,2)+"f", [environment.schoolJaar] : aantalLLPerKlasDezeJaar[key]});
      }
      else if (key.slice(3,4) ===  "7"){
        groepInfo4.push({Groep : key.slice(1,2)+"g", [environment.schoolJaar] : aantalLLPerKlasDezeJaar[key]});
      }
      else{
        console.log(key + "  iets is niet goed groepnr+klasnr 0304  ");
      }
});

console.log(groepInfo4);
let merged = [];


[groepInfo, groepInfo2, groepInfo3, groepInfo4].forEach((hash => a => a.forEach(b => {
  if (!hash[b.Groep]) {
      hash[b.Groep] = {};
      merged.push(hash[b.Groep]);
  }
  Object.assign(hash[b.Groep], b);
}))(Object.create(null)));


console.log(merged);
 const totalschool  = {'VorigeJaarAantal':totalVorigJaar, 'AantalIngeschreven' : ingeschrevenNJ , 'AantalNietIngeschreven': nogNietInNJ ,'AantalNuIngeschreven': totalDezeJaar  }      



      return {totalschool,  merged  };


      }


      async hoofdUpdateme(formvalue) {
        let text;
        let noti;

         try {
          

          let user = await Auth.currentAuthenticatedUser();
          let groups = await user.signInUserSession.accessToken.payload["cognito:groups"];
        
        
        
          if(groups.includes('hoofd') === true ) {
            
            console.log('Hoofd')
        
            await  Auth.updateUserAttributes(user, {'custom:groepnr': formvalue.GroepNr,'custom:klasnr': formvalue.KlasNr});
            await  Auth.currentAuthenticatedUser({ bypassCache: true }); // too refresh tokens with new tokens new data
            
            noti ='success';
            text =`Weergave bekijken docent als is gewijzigd `;
            return {noti, text} ;
            
          }

        } catch (error) {

          await console.log(error);
          noti ='fout';
          text = error;  
        return {noti, text} ;
           
        } 
        }
      
}