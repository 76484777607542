import { Injectable } from '@angular/core';
import { Rapport } from './rapport';



@Injectable()
export class RapportService {

  getRapporten() {
    return [
     new Rapport( 'ResultatenM6-7ToestenMetNamen','Resulten VT R&W Mtoetsen met Namen'),
     new Rapport( 'ResultatenEToestenMetNamen','Resulten VT R&W Etoetsen met Namen'),
     new Rapport( 'RapportMtoetsen','Aantal ingevoerd VT R&W Mtoetsen per Klas '),
     new Rapport( 'EtoetsenRapportage','Aantal ingevoerd VT R&W Etoetsen per Klas')
     
    ];
  }
  


}