import {Injectable} from "@angular/core";
import { Auth, Logger } from 'aws-amplify';
import {environment} from "../../environments/environment";
import { _ } from 'underscore';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import * as SES from "aws-sdk/clients/ses";
import {HelperService} from "./helper.service";
import {CognitoService} from "./cognito.service";
import * as Lambda from "aws-sdk/clients/lambda";
import { ContentObserver } from "@angular/cdk/observers";

const logger = new Logger('DynamoDBAdminService');



@Injectable()
export class DynamoDBAdminService { 

    constructor(public hps: HelperService, public cgn: CognitoService ) { }

    getDC() {
        return Auth.currentCredentials()
          .then(credentials => new DocumentClient({ credentials: credentials , region: environment.region }))  //correctClockSkew: true,
          .catch(err => logger.debug('error getting document client', err));
      };

      getLC() {
        return Auth.currentCredentials()
          .then(credentials => new Lambda({ credentials: credentials , region: environment.region }))  //correctClockSkew: true,
          .catch(err => logger.debug('error getting lambda client', err));
      };

    
      async addNewStudent(formvalue){
        let text;
        let noti;
        const dynamoDb = await this.getDC();
 
        let Item = formvalue;
         let params = {
              TableName: environment.ddbName,
              IndexName: environment.ddbNameIndex1,
               Item,
               ConditionExpression: 'attribute_not_exists(StudentId) AND attribute_not_exists(SchoolJaar) ',
                
            };
      
        try{
        await (dynamoDb as DocumentClient).put(params).promise(); 
        noti ='success'
        text ='toevoegen van nieuwe leerling is succesvol'
        return {noti, text} ;
        }catch (error) {

          if (error.code === 'ConditionalCheckFailedException') {
             noti ='duplicaat'
            text ='leerling is niet toegevoegd want ID en schooljaar bestaat al'  
          return {noti, text} ;

          }
          else{

          console.log(error);
          noti ='fout'
          text ='toevoegen van leerling is fout gegaan !'  
          return {noti, text} ;
          }
        }
      }

      
      async getSchoolbestuurAantal(){

        let schoolbesturen = ['DOS','COM','EBG','ESB','FSHP','MBCB','RAK','RKCS','SCONS','SOZDA','VPCO' ,'DEM' ,'PIPB' , 'SNS' , 'KCB'];
        const db = await this.getDC();

        return Promise.all( schoolbesturen.map(function (sb) {
          let startkey;
          let prome = [];
          let items = [];
          let params = {
               TableName: environment.ddbName,
               IndexName: environment.ddbNameIndex1 ,
               KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
               FilterExpression : 'Ingeschreven = :ing' ,
               //FilterExpression : 'attribute_not_exists(#nsj)' ,
               ExpressionAttributeValues: {
                 ':sbs': sb ,
                 ':scj': environment.schoolJaar,
                 ':ing' : false
               },
               ExpressionAttributeNames: {
                 '#key' : 'SchoolBestuur',
                 '#sortkey' : 'SchoolJaar',
                // '#nsj' :'NieuwSJ'
                 
               },
               ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
               ExclusiveStartKey :startkey
             }

             function next() {
        
               return (db as DocumentClient).query(params).promise().then(function(result) {
                    prome = result.Items;

                if (typeof result.LastEvaluatedKey != "undefined") {
                  params.ExclusiveStartKey = result.LastEvaluatedKey;
                  items = items.concat(result.Items);
                  return next();

             } else {
                  items = items.concat(prome);
                  return items;
             } 
            });
          }
            return next().then(function(result) {
              return result;

        }).catch(function(err) {
          console.log(err);
        });

      })).then(function(responses) {

        let merged = [].concat.apply([], responses);
        console.log(merged);
        return merged;

  }).catch(function(err) {
    console.log(err);
  });
      };


      async getZoekLeerlingmetAttributesa(zoekData){

        let schoolbesturen = ['DOS','COM','EBG','ESB','FSHP','MBCB','RAK','RKCS','SCONS','SOZDA','VPCO','DEM' ,'PIPB' , 'SNS' , 'KCB'];
        const db = await this.getDC();

        return Promise.all( schoolbesturen.map(function (sb) {
          let startkey;
          let prome = [];
          let items = [];
          let params = {
               TableName: environment.ddbName,
               IndexName: environment.ddbNameIndex1 ,
               KeyConditionExpression : '#key = :sbs and #sortkey > :scj' ,
               FilterExpression : zoekData.DynamoDBFilter ,
               ExpressionAttributeValues: {
                 ':sbs': sb ,
                 ':scj': '0',
                 ':qyf' : zoekData.AttrValue
               },
               ExpressionAttributeNames: {
                 '#key' : 'SchoolBestuur',
                 '#sortkey' : 'SchoolJaar',
                // '#nsj' :'NieuwSJ'
                 
               },
               ExclusiveStartKey :startkey
             }

             function next() {
        
               return (db as DocumentClient).query(params).promise().then(function(result) {
                    prome = result.Items;

                if (typeof result.LastEvaluatedKey != "undefined") {
                  params.ExclusiveStartKey = result.LastEvaluatedKey;
                  items = items.concat(result.Items);
                  return next();

             } else {
                  items = items.concat(prome);
                  return items;
             } 
            });
          }
            return next().then(function(result) {
              return result;

        }).catch(function(err) {
          console.log(err);
        });

      })).then(function(responses) {

        let merged = [].concat.apply([], responses);
        console.log(merged);
        return merged;

  }).catch(function(err) {
    console.log(err);
  });
      };

      async getZoekLeerlingmetAttributesSchoolbestuurDOS(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur DOS
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'DOS' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };



      async getZoekLeerlingmetAttributesSchoolbestuurCOM(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur COM
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'COM' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };


      async getZoekLeerlingmetAttributesSchoolbestuurESB(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur ESB
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'ESB' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };



      async getZoekLeerlingmetAttributesSchoolbestuurEBG(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur EBG
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'EBG' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };



      async getZoekLeerlingmetAttributesSchoolbestuurFSHP(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur FSHP
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'FSHP' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };



      async getZoekLeerlingmetAttributesSchoolbestuurMBCB(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur MBCB
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'MBCB' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };




      async getZoekLeerlingmetAttributesSchoolbestuurRAK(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur RAK
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'RAK' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };




      async getZoekLeerlingmetAttributesSchoolbestuurRKCS(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur RKCS
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'RKCS' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };



      async getZoekLeerlingmetAttributesSchoolbestuurSCONS(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur SCONS
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'SCONS' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };



      async getZoekLeerlingmetAttributesSchoolbestuurSOZDA(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur SOZDA
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'SOZDA' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };


      async getZoekLeerlingmetAttributesSchoolbestuurVPCO(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur VPCO
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'VPCO' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };

      async getZoekLeerlingmetAttributesSchoolbestuurDEM(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur DEM
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'DEM' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };


      async getZoekLeerlingmetAttributesSchoolbestuurPIPB(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur PIPB
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'PIPB' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };


      async getZoekLeerlingmetAttributesSchoolbestuurSNS(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur SNS
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'SNS' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };



      async getZoekLeerlingmetAttributesSchoolbestuurKCB(){
        //Zoek leerling met behulp van attributes bij  schoolbestuur KCB
       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'Ingeschreven = :ing' ,
         ExpressionAttributeValues: {
           ':sbs': 'KCB' ,
           ':scj': environment.schoolJaar,
           ':ing' : false
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar'
         },
         ExclusiveStartKey :startkey
       }          
       let items = [];
       const db = await this.getDC();

      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };





      async getZoekLeerlingmetAttributes(){
        //Zoek Leerling bij alle scholen met optie alle schooljaren/ vorige schooljaar of deze schooljaar
      
        let  [DOS, COM,ESB,EBG,FSHP,MBCB,RAK,RKCS,SCONS,SOZDA,VPCO,DEM ,PIPB , SNS , KCB] = await Promise.all([this.getZoekLeerlingmetAttributesSchoolbestuurDOS(),
           this.getZoekLeerlingmetAttributesSchoolbestuurCOM(),this.getZoekLeerlingmetAttributesSchoolbestuurESB(),
           this.getZoekLeerlingmetAttributesSchoolbestuurEBG(),this.getZoekLeerlingmetAttributesSchoolbestuurFSHP(),
          this.getZoekLeerlingmetAttributesSchoolbestuurMBCB(),this.getZoekLeerlingmetAttributesSchoolbestuurRAK(),
          this.getZoekLeerlingmetAttributesSchoolbestuurRKCS(),this.getZoekLeerlingmetAttributesSchoolbestuurSCONS(),
          this.getZoekLeerlingmetAttributesSchoolbestuurSOZDA(),this.getZoekLeerlingmetAttributesSchoolbestuurVPCO(),this.getZoekLeerlingmetAttributesSchoolbestuurDEM(),
          this.getZoekLeerlingmetAttributesSchoolbestuurPIPB(),this.getZoekLeerlingmetAttributesSchoolbestuurSNS(),this.getZoekLeerlingmetAttributesSchoolbestuurKCB()]);
      
          let alles = await DOS.concat(COM, EBG ,ESB , FSHP , MBCB ,RAK , RKCS , SCONS , SOZDA , VPCO , DEM ,PIPB , SNS , KCB) ;

          return alles;
      };

      async getSchoolbestuurESBAantal(){
        //Aantal leerlingen van SchoolBestuur ESB die vorige schooljaar waren en deze schooljaar op geen enkele schoolbestuur/school van curacao bevinden

       let startkey: any;
      
       let params = {
         TableName: environment.ddbName,
         IndexName: environment.ddbNameIndex1 ,
         KeyConditionExpression : '#key = :sbs and #sortkey = :scj' ,
         FilterExpression : 'attribute_not_exists(#nsj)' ,
         ExpressionAttributeValues: {
           ':sbs': 'ESB' ,
           ':scj': environment.vorigeSchoolJaar,
         },
         ExpressionAttributeNames: {
           '#key' : 'SchoolBestuur',
           '#sortkey' : 'SchoolJaar',
           '#nsj' :'NieuwSJ'
         },
         ProjectionExpression: 'Achternaam ,Voornamen,StudentId,SchoolJaar,SchoolNr,GroepNr',
         ExclusiveStartKey :startkey
       }          
       let items = [];
      
         while (true) {
       const db = await this.getDC();
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }
      return _.sortBy(items, 'Achternaam');
      
      };


      async overstappenLeerling(selected, formvalue){
        const db = await this.getDC();


        function getSchoolJaar(arr, prop) {
          let max;
          for (var i=0 ; i<arr.length ; i++) {
              if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop]))
                  max = arr[i];
          }
          return max;
      }

          console.log(selected[0].StudentId);
          let startkey: any;

        // Zoek voor laaste schooljaar van studentenID als het geen dezelfde schooljaar is dan transaction anders update

          let params = {
            TableName: environment.ddbName,
            KeyConditionExpression : '#key = :stu and #sortkey > :scj' ,
            ExpressionAttributeValues: {
              ':stu': selected[0]['StudentId'] ,
              ':scj': '0',
            },
            ExpressionAttributeNames: {
              '#key' : 'StudentId',
              '#sortkey' : 'SchoolJaar'
            },
            ExclusiveStartKey :startkey
          };


          let items = [];
      
         while (true) {
       const data = await (db as DocumentClient).query(params).promise();
       items = items.concat(data.Items);
      
      if (! data.LastEvaluatedKey) {
        break;
      }
      
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      }

      let Item = await  getSchoolJaar(items, "SchoolJaar");
      let vorigeKlas = Object.assign({}, Item);

      if (Item.SchoolJaar === environment.schoolJaar) {

        const params = {
          TableName: environment.ddbName,
          IndexName: environment.ddbNameIndex1,
          Key: {
            'StudentId' : Item.StudentId, 
            'SchoolJaar' : Item.SchoolJaar
          },
          UpdateExpression: 'set Ingeschreven = :ing , SchoolBestuur = :sbs , SchoolNr = :snr , GroepNr = :gnr , KlasNr = :knr ',
          ConditionExpression: 'attribute_exists(StudentId) AND attribute_exists(SchoolJaar) ',
          ExpressionAttributeValues: { 
            ':ing': true,
            ':sbs': formvalue['SchoolBestuur'] ,
            ':snr': formvalue['SchoolNr'] ,
            ':gnr': formvalue['GroepNr'] ,
            ':knr' : formvalue['KlasNr'] 
          },
          ReturnValues:'UPDATED_NEW',   
      };

      try {
        await (db as DocumentClient).update(params).promise();
        return "success";

        
      } catch (error) {
        return error;

      }
     }
     
     else {
       //modifiy schooljaar 
       Item.SchoolJaar = environment.schoolJaar;
       Item.Ingeschreven = true;
       Item.SchoolBestuur = formvalue['SchoolBestuur'];
       Item.SchoolNr = formvalue['SchoolNr'];
       Item.GroepNr = formvalue['GroepNr'] ;
       Item.KlasNr = formvalue['KlasNr'];
       console.log(Item);
       console.log(vorigeKlas);

      let params = {
        TransactItems: [{
          Put: {
            TableName: environment.ddbName,
            IndexName: environment.ddbNameIndex1,
            Item,
            ConditionExpression: 'attribute_not_exists(StudentId) AND attribute_not_exists(SchoolJaar)'
          }
        }, {
          Update: {
            TableName: environment.ddbName,
            IndexName: environment.ddbNameIndex1,
            Key: { 'StudentId' : Item.StudentId,'SchoolJaar' : vorigeKlas.SchoolJaar
            },
            UpdateExpression: 'set NieuwSJ = :nsj',
            ConditionExpression: 'attribute_exists(StudentId) AND attribute_exists(SchoolJaar)AND attribute_not_exists(NieuwSJ)',
            ExpressionAttributeValues: {
              ':nsj': true
            }
          }
        }]
      };

      try {
        await (db as DocumentClient).transactWrite(params).promise();
        return "success";
        
      } catch (error) {

        return error;
        
      }

     }

// nothin to return because if else

        }

        async adminUpdateme(formvalue) {
          let text;
          let noti;

           try {
            

            let user = await Auth.currentAuthenticatedUser();
            let groups = await user.signInUserSession.accessToken.payload["cognito:groups"];
          
          
          
            if(groups.includes('admin') === true ) {
              
              console.log('ADMIN')
          
              await  Auth.updateUserAttributes(user, {'custom:schoolbestuur': formvalue.SchoolBestuur, 'custom:schoolnr': formvalue.SchoolNr,'custom:groepnr': formvalue.GroepNr,'custom:klasnr': formvalue.KlasNr});
              await  Auth.currentAuthenticatedUser({ bypassCache: true }); // too refresh tokens with new tokens new data
              
              noti ='success';
              text =`Weergave bekijken docent als is gewijzigd `;
              return {noti, text} ;
              
            }

          } catch (error) {

            await console.log(error);
            noti ='fout';
            text = error;  
          return {noti, text} ;
             
          } 
          }

          async getRapportage(formvalue){

            const params = {
              FunctionName : formvalue.Rapport,
              InvocationType : 'RequestResponse',
              Payload : JSON.stringify(formvalue.SchoolJaar) ,
              LogType : 'None'
            };
          
            try {
          
              const client = await this.getLC();
              const data = await (client as Lambda).invoke(params).promise();
              await console.log(data);
              return await JSON.parse(JSON.parse(JSON.stringify(data.Payload)));
              
            } catch (error) {
              console.log(error);    
            }
           }



           async getLeerlingbyID(studentid){

            const params = {
              'TableName': environment.ddbName,
              'IndexName': environment.ddbNameIndex1 ,
              'Key': { "StudentId": studentid ,"SchoolJaar": environment.schoolJaar}
          
            };

            try {
              const db = await  this.getDC();
              const data = await (db as DocumentClient).get(params).promise();
              return data.Item;
            } catch (err) {
              return 'FAILED';
            }
 
          }

          async updateStudent(formvalue){
           let params = formvalue;
           let text;
            let noti;
            

            try {
              const db = await  this.getDC();
              await (db as DocumentClient).update(params).promise();
              noti ='success'
              text ='leerling gegevens is gewijzigd!'
              return {noti, text} ;
            } catch (error) {
                 console.log(error.message);
              if (error.code === 'ValidationException' && error.message ==='ExpressionAttributeValues must not be empty') {
                 noti ='fout'
                text ='Er is 1 of meer lege velden dat zijn aangevinkt'  
              return {noti, text} ;
    
              }
             else{
    
             console.log(error);
             noti ='fout'
            text ='wijzigen van leerling is fout gegaan !'  
              return {noti, text} ;
              }
            }
 
          }

          async deleteLeerling(formvalue)  {
            
              let student = formvalue.Key;
              let startkey: any;
              let noti;
              let text;
          
              let params = {
                TableName: environment.ddbCijfer,
                IndexName: environment.ddbCijferStudent,
                KeyConditionExpression : '#key = :stuid and #sortkey = :scj' ,
                ExpressionAttributeValues: {
                    ':stuid': student.StudentId,
                    ':scj': student.SchoolJaar,
                  },
                  ExpressionAttributeNames: {
                    '#key' : 'StudentId',
                    '#sortkey' : 'SchoolJaar'
                  },
                ExclusiveStartKey :startkey
              }
              try {
                
                      
              let items = [];
             
                while (true) {
              const db = await this.getDC();
              const data = await (db as DocumentClient).query(params).promise();
              items = items.concat(data.Items);
          
             if (! data.LastEvaluatedKey) {
               break;
             }
             
             params.ExclusiveStartKey = data.LastEvaluatedKey;
            }

             if (items.length === 0){

              await console.log("ITEMS =0");


              let delparams = {
                'TableName': environment.ddbName,
                'IndexName': environment.ddbNameIndex1 ,
                'Key': { "StudentId": student.StudentId ,"SchoolJaar": student.SchoolJaar}
              };

              try {
                const db = await  this.getDC();
                await (db as DocumentClient).delete(delparams).promise();
                noti ='success'
                text ='leerling gegevens is verwijderd!'
                return {noti, text} ;
              } catch (error) {

                console.log(error);
             noti ='fout';
            text = error;  
              return {noti, text} ;

              }

             } else{

              await console.log("ITEMS items");

              noti ='fout';
            text = "Leerling kan niet worden verwijderd want hij/zij heeft ingevoerde AWB(S)!";  
              return {noti, text} ;
             }
           }catch (error) {

            console.log(error);
             noti ='fout';
            text = error;  
              return {noti, text} ;
        
          }  
            
              };





}          


