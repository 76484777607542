// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  configFile: 'assets/constant.json' ,


  schoolJaar: '2324',
  vorigeSchoolJaar : '2223',
  tweejaarGeleden :  '2122' ,
  driejaarGeleden :  '2021' ,




  region: 'us-east-1',

  ddbName: 'LeerlingenDB',
  ddbNameIndex1: 'SchoolBestuur-index',
  ddbLogs: 'GebruikersLogsDB',
  ddbCijfer: 'ResultaatDB',
  ddbCijferStudent: 'StudentId-index',
  ddbCijferLeraar: 'SubId-index',
  ddbCijferToets : 'ToetsNaam-index',
  // for TTL Expire Value is 3 and M is Month check MomentTimezone for more info so now item expires 3 months in future
  expValue: '3',

  //cognito userpoolId
  UserPoolId: 'us-east-1_5alxQRyNG',

  //AWS SES
  //Semail = SourceEmail
  //Demail = DestinationEmail

  srcEmail: 'FlexIT System <no-reply-wnk@flexitapps.io>',
  destEmail: 'chrisbadboy1@gmail.com',

  //Angular WebApp Roles

  BSL3: 'admin',
  BSL2: 'hoofd',
  BSL1: 'docent',



  intercomAppId: 'tnujc6kg',

  slackError:'https://hooks.slack.com/services/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' 


};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
