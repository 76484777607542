import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import { AuthGuard } from './service/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [

      {
        path: '',
        redirectTo: 'dashboard/default',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: './theme/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'navigation',
        loadChildren: './theme/navigation/navigation.module#NavigationModule'
      },
      
      {
        path: 'user',
        loadChildren: './theme/user/user.module#UserModule'
      },
      {
        path: 'klas-overzicht',
        loadChildren: './theme/docent/klas-overzicht/klas-overzicht.module#KlasOverzichtModule'
      },
      {
        path: 'klas-inschrijven',
        loadChildren: './theme/docent/klas-inschrijven/klas-inschrijven.module#KlasInschrijvenModule'
      },
      {
        path: 'klas-uitschrijven',
        loadChildren: './theme/docent/klas-uitschrijven/klas-uitschrijven.module#KlasUitschrijvenModule'
      },
      {
        path: 'klas-resultaat-RW/klas-resultaat',
        loadChildren: './theme/docent/klas-resultaat/klas-resultaat.module#KlasResultaatModule'
      },
      {
        path: 'klas-resultaat-RW/klasr-invoeren',
        loadChildren: './theme/docent/klasr-invoeren/klasr-invoeren.module#KlasrInvoerenModule'
      },
      {
        path: 'klas-resultaat-RW/klasr-verwijderen',
        loadChildren: './theme/docent/klasr-verwijderen/klasr-verwijderen.module#KlasrVerwijderenModule'
      },
      
      {
        path: 'klas-resultaat-NP/klas-resultaat-np',
        loadChildren: './theme/docent/klas-resultaat-np/klas-resultaat-np.module#KlasResultaatNPModule'
      },
      {
        path: 'klas-resultaat-NP/klasr-invoeren-np',
        loadChildren: './theme/docent/klasr-invoeren-np/klasr-invoeren-np.module#KlasrInvoerenNPModule'
      },
      {
        path: 'klas-resultaat-NP/klasr-verwijderen-np',
        loadChildren: './theme/docent/klasr-verwijderen-np/klasr-verwijderen-np.module#KlasrVerwijderenNPModule'
      },
      {
        path: 'vt-rw/leerling-profiel',
        loadChildren: './theme/docent/leerling-rapport/leerling-rapport.module#LeerlingRapportModule'
      },
      {
        path: 'vt-rw/groeps-profiel',
        loadChildren: './theme/docent/groeps-rapport/groeps-rapport.module#GroepsRapportModule'
      },
      {
        path: 'vt-rw/school-profiel',
        loadChildren: './theme/docent/school-rapport/school-rapport.module#SchoolRapportModule'
      },
      {
        path: 'leerling-toevoegen',
        loadChildren: './theme/admin/leerling-toevoegen/leerling-toevoegen.module#LeerlingToevoegenModule'
      },
      {
        path: 'leerling-verwijderen',
        loadChildren: './theme/admin/leerling-verwijderen/leerling-verwijderen.module#LeerlingVerwijderenModule'
      },
      {
        path: 'leerling-zoeken',
        loadChildren: './theme/admin/leerling-zoeken/leerling-zoeken.module#LeerlingZoekenModule'
      },
      {
        path: 'leerling-wijzigen',
        loadChildren: './theme/admin/leerling-wijzigen/leerling-wijzigen.module#LeerlingWijzigenModule'
      },
      {
        path: 'leerling-overstappen',
        loadChildren: './theme/admin/leerling-overstappen/leerling-overstappen.module#LeerlingOverstappenModule'
      },
      {
        path: 'bekijken-als',
        loadChildren: './theme/admin/bekijken-als/bekijken-als.module#BekijkenAlsModule'
      },
      {
        path: 'admin-rapport',
        loadChildren: './theme/admin/admin-rapport/admin-rapport.module#RapportModule'
      },
      {
        path: 'admin-docent-resultaten-rapport/vt-rw/leerling-profiel',
        loadChildren: './theme/docent/leerling-rapport/leerling-rapport.module#LeerlingRapportModule'
      },{
        path: 'admin-docent-resultaten-rapport/vt-rw/groeps-profiel',
        loadChildren: './theme/docent/groeps-rapport/groeps-rapport.module#GroepsRapportModule'
      },{
        path: 'admin-docent-resultaten-rapport/vt-rw/school-profiel',
        loadChildren: './theme/docent/school-rapport/school-rapport.module#SchoolRapportModule'
      },
      {
        path: 'admin-docent-leerlingen/klasoverzicht',
        loadChildren: './theme/docent/klas-overzicht/klas-overzicht.module#KlasOverzichtModule'
      },
      {
        path: 'admin-docent-leerlingen/klas-inschrijven/mijnklas-inschrijven',
        loadChildren: './theme/docent/klas-inschrijven/mijnklas-inschrijven/mijnklas-inschrijven.module#MijnKlasInschrijvenModule'
      },{
        path: 'admin-docent-leerlingen/klas-inschrijven/mijnschool-inschrijven',
        loadChildren: './theme/docent/klas-inschrijven/mijnschool-inschrijven/mijnschool-inschrijven.module#MijnSchoolInschrijvenModule'
      },{
        path: 'admin-docent-leerlingen/klas-inschrijven/allescholen-inschrijven',
        loadChildren: './theme/docent/klas-inschrijven/allescholen-inschrijven/allescholen-inschrijven.module#AlleScholenInschrijvenModule'
      },{
        path: 'admin-docent-leerlingen/klas-inschrijven/mijnschoolnj-inschrijven',
        loadChildren: './theme/docent/klas-inschrijven/mijnschoolnj-inschrijven/mijnschoolnj-inschrijven.module#MijnSchoolNJInschrijvenModule'
      },{
        path: 'admin-docent-leerlingen/klas-inschrijven/eenschoolnj-inschrijven',
        loadChildren: './theme/docent/klas-inschrijven/eenschoolnj-inschrijven/eenschoolnj-inschrijven.module#EenSchoolNJInschrijvenModule'
      },
      {
        path: 'admin-docent-leerlingen/klas-uitschrijven',
        loadChildren: './theme/docent/klas-uitschrijven/klas-uitschrijven.module#KlasUitschrijvenModule'
      },
      {
        path: 'admin-docent-resultaten/klas-resultaat-RW/groepsoverzicht',
        loadChildren: './theme/docent/klas-resultaat/klas-resultaat.module#KlasResultaatModule'
      },
      {
        path: 'admin-docent-resultaten/klas-resultaat-RW/resultaten-invoeren',
        loadChildren: './theme/docent/klasr-invoeren/klasr-invoeren.module#KlasrInvoerenModule'
      },
      {
        path: 'admin-docent-resultaten/klas-resultaat-RW/resultaten-verwijderen',
        loadChildren: './theme/docent/klasr-verwijderen/klasr-verwijderen.module#KlasrVerwijderenModule'
      },
      {
        path: 'admin-docent-resultaten/klas-resultaat-NP/klas-resultaat-np',
        loadChildren: './theme/docent/klas-resultaat-np/klas-resultaat-np.module#KlasResultaatNPModule'
      },
      {
        path: 'admin-docent-resultaten/klas-resultaat-NP/klasr-invoeren-np',
        loadChildren: './theme/docent/klasr-invoeren-np/klasr-invoeren-np.module#KlasrInvoerenNPModule'
      },
      {
        path: 'admin-docent-resultaten/klas-resultaat-NP/klasr-verwijderen-np',
        loadChildren: './theme/docent/klasr-verwijderen-np/klasr-verwijderen-np.module#KlasrVerwijderenNPModule'
      },
      {
        path: 'bekijken-als-hoofd',
        loadChildren: './theme/hoofd/bekijken-als/bekijken-als.module#BekijkenAlsModule'
      },
      
     
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: './theme/auth/auth.module#AuthModule'
      },
      {
        path: 'maintenance/error',
        loadChildren: './theme/maintenance/error/error.module#ErrorModule'
      },
      {
        path: 'maintenance/offline-ui',
        loadChildren: './theme/maintenance/offline-ui/offline-ui.module#OfflineUiModule'
      },
      {
        path: 'not-found',
        loadChildren: './theme/maintenance/error/error-404/error-404.module#Error404Module'
      },
      {
        path: '**',
        redirectTo: 'not-found'
      }
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
