import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import { _ } from 'underscore';
import * as moment from 'moment-timezone';
import { Auth, Logger } from 'aws-amplify';
import {SchoolNaamService} from './schoolnaam.service';



import * as CognitoIdentityServiceProvider from "aws-sdk/clients/cognitoidentityserviceprovider"


const logger = new Logger('CognitoService');






@Injectable()
export class CognitoService {

    session;


    constructor(public schn: SchoolNaamService) { }



   getCISP() {
      return Auth.currentCredentials()
        .then(credentials => new CognitoIdentityServiceProvider({ credentials: credentials , region: environment.region }))
        .catch(err => logger.debug('error getting AWS CognitoIdentityServiceProvider Client', err));
    };

       
    async getUserInfo(){

      try {
        
      
      
      let naam: any;
        let telephone : any;
        let group : any = "geen";
        let  email : any;
        let sub : any;
        let schoolbestuur : any = "geen";
        let groepnr : any = "geen";
        let klasnr : any = "geen";
        let schoolnr : any = "geen";
        let schoolnaam : any = "geen";

        let mysession = await Auth.currentSession();
        this.session = await this.refreshSessionPromise(mysession.getRefreshToken());

        naam = this.session.idToken.payload['name'];
          telephone = this.session.idToken.payload['phone_number'];
          group = this.session.idToken.payload['cognito:groups'];
          email = this.session.idToken.payload['email'];
          sub = this.session.idToken.payload['sub'];
          schoolbestuur = this.session.idToken.payload['custom:schoolbestuur'];
          groepnr = this.session.idToken.payload['custom:groepnr'];
          klasnr = this.session.idToken.payload['custom:klasnr'];
          schoolnr = this.session.idToken.payload['custom:schoolnr'];

        let schoolinfo= this.schn.getSchoolNamen().filter((item) => item.schoolNr == this.session.idToken.payload['custom:schoolnr']);
          schoolnaam = schoolinfo.map(function(item) {
        return item['schoolNaam'];
      });

      return   {
        naam: naam ,
        telefoon: telephone ,
        geslacht : 'Geen',
        group : group ,
        email : email ,
        sub : sub ,
        schoolbestuur : schoolbestuur ,
        groepnr : groepnr ,
        klasnr : klasnr ,
        schoolnr : schoolnr ,
        schoolnaam : schoolnaam
            }


          } catch (error) {
            console.log(error);
        
          }

    }


       
    
      getUserAttributes(){
          
        let naam: any;
        let telephone : any;
        let group : any = "geen";
        let  email : any;
        let sub : any;
        let schoolbestuur : any = "geen";
        let groepnr : any = "geen";
        let klasnr : any = "geen";
        let schoolnr : any = "geen";
        let schoolnaam : any = "geen";

  
         return Auth.currentAuthenticatedUser().then(session => {   
          return Auth.userAttributes(session)}).then(session => {
        this.session = session;
        session.forEach(function(item) {

          console.log(item);

          if (item['Name'] ===  "name"){
            naam =item['Value'];
            }
          else if 
          (item['Name'] ===  "phone_number"){
            telephone =item['Value'];
            }
            else if 
            (item['Name'] ===  "email"){
              email =item['Value'];
              }
              else if 
              (item['Name'] ===  "sub"){
                sub =item['Value'];
                }
                else if 
              (item['Name'] ===  "custom:schoolbestuur"){
                schoolbestuur =item['Value'];
                }
                else if 
              (item['Name'] ===  "custom:groepnr"){
                groepnr =item['Value'];
                }
                else if 
              (item['Name'] ===  "custom:klasnr"){
                klasnr =item['Value'];
                }
                else if 
              (item['Name'] ===  "custom:schoolnr"){
                schoolnr =item['Value'];
                let schoolinfo= this.schn.getSchoolNamen().filter((item) => item.schoolNr == item['Value']);
               schoolnaam = schoolinfo.map(function(item) {return item['schoolNaam'];});

                }
                else{

                  console.log('weird not supose to be here '+ item);
                }
         
        });


    


     


         groepnr = this.session.name;

    
         return   {
          groepnr : naam

              }
    
        }).then(function (persoon) { 
          return persoon;
        })
        .catch(err => console.log(err));
          
      }

      async AdmingetUsers(){
        const cgi = await this.getCISP();
        let token = ''
  
        let params = {
          UserPoolId: environment.UserPoolId ,
          AttributesToGet: [
            'name','email','phone_number'
          ],// 'sub'
        };
  
        try {
          const data = await (cgi as CognitoIdentityServiceProvider).listUsers(params).promise();
          
          return data;
        } catch (err) {
      
         console.log(err );
      
         return err;
         
        }
      
      }

      async AdmingetUsers2(){
        const cgi = await this.getCISP();
        let pagtoken: any;

  
        let params = {
          UserPoolId: environment.UserPoolId ,
          // 'sub'
          PaginationToken: pagtoken,
        };

        let users = [];

        while (true) {
          const data = await (cgi as CognitoIdentityServiceProvider).listUsers(params).promise();
          users = users.concat(data.Users);
      
         if (! data.PaginationToken) {
           break;
         }
         
         params.PaginationToken = data.PaginationToken;
       }
       
       return (users);
      
      }

      async AdmingetUserinGroup(){
        const cgi = await this.getCISP();
        let nexttoken: any;

  
        let params = {
          UserPoolId: environment.UserPoolId ,
          GroupName :'docent' ,
          NextToken: nexttoken,
        };

        let users = [];

        while (true) {
          const data = await (cgi as CognitoIdentityServiceProvider).listUsersInGroup(params).promise();
          users = users.concat(data.Users);
      
         if (! data.NextToken) {
           break;
         }
         
         params.NextToken = data.NextToken;
       }
       
       return (users);
      
      }


      async refreshSessionPromise(refreshToken) {
        return new Promise(async (resolve, reject) => {
          const user = await Auth.currentAuthenticatedUser();
          return user.refreshSession(refreshToken, async (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data); // THIS IS YOUR REFRESHED ATTRIBUTES/GROUPS
            }
          });
        });
      }

      async refreshCurrentSession() {
        const session = await Auth.currentSession();
        return this.refreshSessionPromise(session.getRefreshToken());
      }
  }
