import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "llpPgGroup"
})
// LeerlingProfiel PG Property Calculation di GroupBy di NGX Charts dor di count tur score i tur ms i despues 
export class LlpPgGroupPipe implements PipeTransform {

    transform(items: any[]): any {
        let ms = 0;
        let sc = 0;
        let pg = 0;
         sc = items.reduce((a, b) => a + b.score, 0);
         ms  = items.reduce((a, b) => a + b.ms, 0);
         pg = sc *100 / ms;
         return pg;
    }
}


