import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import { _ } from 'underscore';
import * as moment from 'moment-timezone';
import { Auth, Logger } from 'aws-amplify';
import * as SES from "aws-sdk/clients/ses";



const logger = new Logger('MailService');






@Injectable()
export class MailService {



    constructor() { }



    getSES() {
      return Auth.currentCredentials()
        .then(credentials => new SES({ credentials: credentials , region: environment.region }))
        .catch(err => logger.debug('error getting AWS EmailClient', err));
    };


    async sendMail(err)  {
      const ses = await this.getSES();
        //set time and userinfo
      const params = {
        Destination: {
            ToAddresses: [environment.destEmail]
        },
        Message: {
            Body: {
                Text: {
                    Data: err
                }
            },
            Subject: {
                Data: 'ETE APP Error'
            }
        },
        Source: environment.srcEmail
    };
 
 
      const data = await (ses as any).sendEmail(params).promise();
    
    return data ;
       
    };



    }
